import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import HomePageApi from "../API/HomePageApi";
import { Skeleton } from "@mui/material";
import { Fade } from "react-awesome-reveal";

export default function BasicPieChart(props) {
  const {
    id,
    chartHeight,
    heading,
    colColors,
    facilityId,
    startDate,
    endDate,
    feature,
    medicalStatus,
  } = props;
  const [PieStatsApi, setPieStatsApi] = useState([]);
  const [xCategories, setXCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await HomePageApi.GetPieFeaturesStats(
        facilityId,
        startDate,
        endDate,
        medicalStatus,
        feature
      );
      const mergedCounts = {};
      const apiData = response.data.forEach(({ label, count }) => {
        const key =
          label === null || label === "string" ? "Not Specified" : label;
        mergedCounts[key] = (mergedCounts[key] || 0) + count;
      });
      const mergedArray = Object.entries(mergedCounts).map(
        ([label, count]) => ({ label, count })
      );
      setPieStatsApi(mergedArray);
      setXCategories(mergedArray.map((item) => item.label));
    };

    fetchData();
  }, [facilityId, startDate, endDate, feature, medicalStatus]);

  // const order = xCategories;

  // const sortedData = order?.map((label) => {
  //   const dataItem = PieStatsApi?.find((item) => item.label === label);
  //   return {
  //     label: label,
  //     count: dataItem ? dataItem.count : 0,
  //   };
  // });

  const state = {
    series: PieStatsApi.map((item) => item.count),
    // series: PieStatsApi?.map((item) => item.count),
    options: {
      chart: {
        height: chartHeight,
        type: "pie",
        toolbar: { show: false },
      },
      dataLabels: {
        offsetX: -30,
      },
      plotOptions: {
        pie: {
          offsetX: -70,
          offsetY: 35,
          right: 30,
        },
      },
      labels: PieStatsApi?.map((item) => item.label),
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "right",
        floating: true,
        offsetX: 30,
        horizontalAlign: "right",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        markers: {
          radius: 10,
          offsetY: 2,
        },
      },
      tooltip: {
        enabled: true,
      },
      title: {
        text: heading,
        align: "left",
        style: {
          fontSize: "24px",
          fontWeight: "700",
          fontFamily: "poppins",
          color: "#000000",
        },
      },
      colors: colColors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  return (
    <div className="p-2" key={id}>
      <div id="chart">
        {PieStatsApi.length > 0 ? (
          <Fade delay={1e2}>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="pie"
              height={chartHeight}
            />
          </Fade>
        ) : (
          <Skeleton height={chartHeight} />
        )}
      </div>
    </div>
  );
}
