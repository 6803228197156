import React, { useEffect, useRef, useState } from "react";
import {
  Compare,
  Delete,
  Done,
  EditNote,
  RotateLeft,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  Chip,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
} from "@mui/material";
import { Link } from "react-router-dom";
import GetData from "../../API/GetData";
import moment from "moment";
import CompareCard from "../../Atoms/CompareCard";
import No_Data from "../../Assets/Images/No_Data.svg";
import Viewer from "react-viewer";
import ReactPanZoom from "react-image-pan-zoom-rotate";

export default function Index() {
  const [approvalList, setapprovalList] = useState([]);
  const [refreshApi, setRefreshApi] = useState(false);
  const [sendingReq, setsendingReq] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [tablesData, setTablesData] = useState([]);
  const [originalTable, setoriginalTable] = useState([]);
  const [updatedTable, setupdatedTable] = useState([]);
  const [visit_imagesList, setvisit_imagesList] = useState([]);
  const [sendingTableReq, setsendingTableReq] = useState(false);
  const [clickedVisit, setClickedVisit] = useState();
  const [openCompareTable, setopenCompareTable] = useState(false);
  const [imgRotate, setimgRotate] = useState(0);
  const [imageURL, setimageURL] = useState("");
  const [first_approval, setfirst_approval] = useState(true);
  const [visible, setVisible] = useState(imageURL ? true : false);
  const containerRef = React.useRef();
  const [selectedValues, setSelectedValues] = useState([]);
  const [addAbleComment, setaddAbleComment] = useState("");
  const [commentVisitId, setCommentVisitId] = useState("");
  const [commentedBy, setcommentedBy] = useState("");
  const [commentOnFields, setcommentOnFields] = useState([]);
  const [fileCount, setfileCount] = useState(0);
  const [coughingCount, setcoughingCount] = useState(0);
  const [breathingCount, setbreathingCount] = useState(0);
  const [testReportCount, settestReportCount] = useState(0);
  const [formCount, setformCount] = useState(0);
  const [consentFormCount, setconsentFormCount] = useState(0);

  useEffect(() => {
    setsendingReq(true);
    GetData.ApproveList(first_approval).then((res) => {
      res = res.data;
      setapprovalList(res);
      setRefreshApi(false);
      setsendingReq(false);
    });
  }, [refreshApi]);

  return (
    <div className="container mx-auto">
      <p className="fs-24 fw-bold Lato">Forms for Validation with Images</p>
      <div className="d-inline-flex w-100 h-200 position-relative">
        {sendingReq ? (
          <div className="text-center d-flex h-100 flex-column w-100 text-center">
            <div className="my-auto">
              <CircularProgress />
              <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                Loading...
              </p>
            </div>
          </div>
        ) : approvalList.length > 0 ? (
          approvalList?.map((each, key) => {
            return (
              <div className="p-2">
                <Paper
                  sx={{
                    p: 1,
                    borderRadius: 2,
                    gap: 2,
                    height: "100%",
                    opacity: clickedVisit === each.visit_id ? 0.8 : 1,
                    border:
                      clickedVisit === each.visit_id && "2px solid #1470a4",
                    boxShadow: "0px 0px 10px 0px #0000001A",
                    backgroundColor:
                      clickedVisit === each.visit_id && "#F5F5F5",
                    color: clickedVisit === each.visit_id && "#1470a4",
                    transition: "ease-in-out 0.3s",
                  }}
                >
                  <div className="row">
                    <div className="col-6">
                      <p className="fs-16 fw-bold Lato">
                        Visit ID: {each.visit_id}
                        <Link to={`/onfirstupdated/${each.visit_id}`}>
                          <EditNote className="fs-3 ms-3 text-primary" />
                        </Link>
                      </p>
                    </div>
                    <div className="col-6">
                      <p className="fs-16 fw-bold Lato text-md-start">
                        {moment(each._datetime).format("YYYY-MM-DD")}
                      </p>
                    </div>
                  </div>
                  <Stack direction="row" spacing={1}>
                    <button
                      className="w-120 btn btn-primary text-light text-center h-40 Lato d-inline-flex"
                      onClick={() => {
                        setsendingTableReq(true);
                        setVisible(false);
                        setSelectedValues([]);
                        setimageURL("");
                        const visit_Id = each.visit_id;
                        setClickedVisit(each.visit_id);
                        setopenCompareTable(false);
                        {
                          visit_Id &&
                            GetData.CompareTable(visit_Id, first_approval)
                              .then((res) => {
                                res = res.data;
                                setCommentVisitId(visit_Id);
                                setTablesData(res);
                                setoriginalTable(res.original);
                                setupdatedTable(res.updated);
                                setvisit_imagesList(res.visit_images);
                                setopenCompareTable(true);
                                setsendingTableReq(false);
                              })
                              .catch((error) => {
                                setErrorMessage(
                                  "There was a problem fetching the Data"
                                );
                              });
                          GetData.GetRecordingsById(visit_Id).then((value) => {
                            if (value !== undefined) {
                              const data = value.data;
                              const getCountByType = (type) => {
                                return data?.filter(
                                  (label) => label.type === type
                                ).length;
                              };
                              setfileCount(getCountByType("file"));
                              setcoughingCount(getCountByType("coughing"));
                              setbreathingCount(getCountByType("breathing"));
                              settestReportCount(getCountByType("test-report"));
                              setformCount(getCountByType("form"));
                              setconsentFormCount(
                                getCountByType("consent-form")
                              );
                            }
                          });
                        }
                      }}
                    >
                      <span className="d-none my-auto d-md-block fs-12 mx-auto">
                        Compare
                      </span>
                      <Compare className="fs-3 ms-md-1 mx-auto my-auto" />
                    </button>
                    <button
                      className="w-120 btn btn-success text-light text-center h-40 Lato d-inline-flex"
                      onClick={() => {
                        const visit_Id = each.visit_id;
                        setVisible(false);
                        setSelectedValues([]);
                        setopenCompareTable(false);
                        setRefreshApi(true);
                        GetData.Approval(visit_Id, first_approval)
                          .then((res) => {
                            res = res.data;
                            setsuccessMessage(res.detail);
                            setRefreshApi(true);
                          })
                          .catch((error) => {
                            setErrorMessage(
                              "There was a problem Approving the form"
                            );
                          });
                      }}
                    >
                      <span className="d-none my-auto d-md-block fs-12 mx-auto">
                        Validate
                      </span>
                      <Done className="fs-3 ms-md-1 mx-auto my-auto" />
                    </button>
                    {/* <button
                      className="w-120 btn btn-warning text-light text-center h-40 Lato d-inline-flex"
                      onClick={() => {
                        const visit_Id = each.visit_id;
                        setopenCompareTable(false);
                        setRefreshApi(true);
                        GetData.Rejection(visit_Id, first_approval)
                          .then((res) => {
                            res = res.data;
                            setsuccessMessage(res.detail);
                            setRefreshApi(true);
                          })
                          .catch((error) => {
                            setErrorMessage(
                              "There was a problem Rejecting the form"
                            );
                          });
                      }}
                    >
                      <span className="d-none my-auto d-md-block fs-12 mx-auto">
                        Reject
                      </span>
                      <Delete className="fs-3 ms-md-1 mx-auto my-auto" />
                    </button> */}
                  </Stack>
                </Paper>
              </div>
            );
          })
        ) : (
          <p className="fs-16 fw-bold Lato text-danger text-center">
            No forms for approval
          </p>
        )}
      </div>
      {localStorage.getItem("role") === process.env.REACT_APP_ANALYST && (
        <div className="row my-3">
          <div className="col-md-5">
            <input
              className="form-control"
              disabled={true}
              value={selectedValues}
              placeholder="Select From Below"
            />
          </div>
          <div className="col-md-5">
            <input
              className="form-control"
              placeholder="Enter Issue Here"
              onChange={(e) => setaddAbleComment(e.target.value)}
              value={addAbleComment}
            />
          </div>
          <div className="col-md-2">
            <button
              className="btn btn-primary text-light h-100 Lato"
              disabled={
                selectedValues.length === 0 && !addAbleComment ? true : false
              }
              onClick={() => {
                const visit_Id = commentVisitId;
                const concatinateComent =
                  selectedValues + " || " + addAbleComment;
                GetData.ApproveVisitComment(
                  concatinateComent,
                  visit_Id,
                  first_approval
                ).then((res) => {
                  res = res.data;
                  setsuccessMessage(res.detail);
                  setSelectedValues([]);
                  setaddAbleComment("");
                });
                GetData.Approval(visit_Id, first_approval)
                  .then((res) => {
                    res = res.data;
                    setsuccessMessage(res.detail);
                    setRefreshApi(true);
                  })
                  .catch((error) => {
                    setErrorMessage("There was a problem Approving the form");
                  });
                setopenCompareTable(false);
                setVisible(false);
              }}
            >
              Add Comment
            </button>
          </div>
        </div>
      )}
      <Box
        className="container row mx-auto h-100"
        sx={{ p: 3, paddingBottom: 0 }}
      >
        <p className="fs-24 fw-bold Lato text-blue">
          Compare form
          <span className="ms-3">
            {fileCount !== 0 && (
              <Chip
                variant="outlined"
                className="fs-16 me-2 mb-2 rounded-4"
                label={`File: ${fileCount || "_"}`}
                sx={{
                  borderRadius: "16px",
                  fontWeight: "400",
                  color: "#B54708",
                  borderColor: "#B54708",
                  backgroundColor: "#FFFAEB",
                }}
              />
            )}
            {coughingCount !== 0 && (
              <Chip
                variant="outlined"
                className="fs-16 me-2 mb-2 rounded-4"
                label={`Coughing: ${coughingCount || "_"}`}
                sx={{
                  borderRadius: "16px",
                  fontWeight: "400",
                  color: "#B54708",
                  borderColor: "#B54708",
                  backgroundColor: "#FFFAEB",
                }}
              />
            )}
            {breathingCount !== 0 && (
              <Chip
                variant="outlined"
                className="fs-16 me-2 mb-2 rounded-4"
                label={`Breathing: ${breathingCount || "_"}`}
                sx={{
                  borderRadius: "16px",
                  fontWeight: "400",
                  color: "#B54708",
                  borderColor: "#B54708",
                  backgroundColor: "#FFFAEB",
                }}
              />
            )}
            {testReportCount !== 0 && (
              <Chip
                variant="outlined"
                className="fs-16 me-2 mb-2 rounded-4"
                label={`Test Report: ${testReportCount || "_"}`}
                sx={{
                  borderRadius: "16px",
                  fontWeight: "400",
                  color: "#B54708",
                  borderColor: "#B54708",
                  backgroundColor: "#FFFAEB",
                }}
              />
            )}
            {formCount !== 0 && (
              <Chip
                variant="outlined"
                className="fs-16 me-2 mb-2 rounded-4"
                label={`Form: ${formCount || "_"}`}
                sx={{
                  borderRadius: "16px",
                  fontWeight: "400",
                  color: "#B54708",
                  borderColor: "#B54708",
                  backgroundColor: "#FFFAEB",
                }}
              />
            )}
            {consentFormCount !== 0 && (
              <Chip
                variant="outlined"
                className="fs-16 mb-2 rounded-4"
                label={`Consent Form: ${consentFormCount || "_"}`}
                sx={{
                  borderRadius: "16px",
                  fontWeight: "400",
                  color: "#B54708",
                  borderColor: "#B54708",
                  backgroundColor: "#FFFAEB",
                }}
              />
            )}
          </span>
        </p>
        {openCompareTable ? (
          <div className="row gx-5">
            <div className="col-md-4 p-4">
              <CompareCard
                compareState={originalTable}
                profileInfo={updatedTable}
                sendingReq={sendingTableReq}
                tableName="Updated"
                ImageCompare={true}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                IsCheckBoxVisible={
                  localStorage.getItem("role") === process.env.REACT_APP_ANALYST
                    ? true
                    : false
                }
              />
            </div>
            <div className="col-md-8 p-4">
              <div className="row mb-4">
                {visit_imagesList.length !== 0 ? (
                  visit_imagesList.map((index, key) => (
                    <button
                      key={key}
                      className="btn btn-outline-primary col-3 col-md-1 py-2 fs-10 Lato text-dark me-3 ONHOVER-IMAGE"
                      onClick={() => {
                        setimageURL(index);
                        setVisible(true);
                      }}
                    >
                      <img
                        src={index}
                        // alt={index?.split("/")[4]}
                        width={100}
                        height={100}
                      />
                      <div className="onHover-overlay">
                        <span>{index?.split("/")[5]?.split("_")[0]}</span>
                        <br />
                        <span>
                          {moment
                            .unix(
                              index?.split("/")[5]?.split("_")[2].split(".")[0]
                            )
                            .format("DD MMM, YYYY")}
                        </span>{" "}
                      </div>
                    </button>
                  ))
                ) : (
                  <p className="fs-16 fw-bold Lato text-danger text-center">
                    No Images to Load
                  </p>
                )}
                {/* <div className="container border-3 border-primary vh-75">
                  <div className="row my-2">
                    <button
                      className="btn btn-secondary fs-1 col-1 text-light me-3"
                      onClick={() => setimgRotate(imgRotate + 90)}
                    >
                      <RotateLeft className="fs-1" />
                    </button>
                  </div>
                  <div className="row img-div">
                    <img
                      src={`https://dctapi.ai4lyf.com/record/pr/${imageURL}`}
                      alt={imageURL}
                      style={{
                        transform: `rotate(${imgRotate}deg)`,
                        transition: "all 0.3s",
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                      }}
                      className="mt-5 pt-5"
                    />
                  </div>
                </div> */}

                <div className="img-div overflow-hidden border-3 rounded-4 position-relative bg-dark mt-3">
                  <ReactPanZoom
                    image={imageURL}
                    alt={imageURL?.split("/")[5]?.split("_")[0]}
                  />
                  {/* <Viewer
                    visible={visible}
                    container={containerRef.current}
                    onClose={() => {
                      setVisible(false);
                    }}
                    images={[
                      {
                        src: imageURL,
                        alt: imageURL?.split("/")[5]?.split("_")[0],
                      },
                    ]}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center d-flex h-100 flex-column transition-loading">
            <div className="my-auto">
              <img src={No_Data} />
              <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                Click Compare button to compare the tables
              </p>
            </div>
          </div>
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        onClose={() => {
          setsuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
