import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import {
  DocumentScanner,
  ExpandMore,
  Image,
  MusicNote,
} from "@mui/icons-material";
import moment from "moment";

export default function RecordingsCard(props) {
  const {
    recordingsList,
    setisLoading,
    setfetchUrl,
    setfileName,
    fileName,
    setfetchImgId,
    setAnnComment,
    setselectedOpt,
    setmodelPredictions,
    visitsList,
    setVisit_Id,
    visit_Id,
    LoadingRecords,
    setLoadingRecords,
    setrecordingsList,
  } = props;

  return (
    <div className="graph-cards mb-4">
      <p className="fs-20 fw-700 text-blue poppins-Med">
        TB Patient Cough Recording
      </p>
      {visitsList.length > 0 ? (
        visitsList?.map((each) => {
          return (
            <Accordion className="mb-3" expanded={each.visit_id == visit_Id}>
              <AccordionSummary
                className="fw-700 fs-16 bg-secndry"
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => {
                  setVisit_Id(
                    each.visit_id === visit_Id ? null : each.visit_id
                  );
                }}
              >
                {/* {each.patient_type + "Asas"} */}
                <span className="ms-auto me-3">
                  {moment(each.date).format("YYYY-MM-DD")}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {LoadingRecords ? (
                  <div className="w-100 d-flex">
                    <CircularProgress className="mx-auto" />
                  </div>
                ) : (
                  recordingsList?.length > 0 &&
                  recordingsList?.map((each, key) => {
                    return (
                      <p
                        className={`text-blue fs-5 ${
                          each.file_name === fileName && "selected-recording"
                        } `}
                        role="button"
                        key={each.id}
                        onClick={() => {
                          setisLoading(true);
                          setfileName(
                            each.file_name.includes(".jpg")
                              ? null
                              : each.file_name
                          );
                          if (each.file_name.includes(".jpg"))
                            window.open(each.url, "_blank");
                          else {
                            setfetchUrl(each.url);
                            setfetchImgId(each.id);
                          }
                          setAnnComment(each.comment ? each.comment : "");
                          setselectedOpt(
                            each.noise_type ? each.noise_type : []
                          );
                          setmodelPredictions([each]);
                        }}
                      >
                        <p>
                          {key + 1}
                          {") "} id= '{each.file_name}' (
                          {each._datetime
                            ? moment(each._datetime).format("YYYY-MM-DD") +
                              " DT"
                            : moment(each.created_at).format("YYYY-MM-DD") +
                              " CR"}
                          )
                          <span>
                            {each.file_name?.includes("jpg") ? (
                              <Image className="fs-18" />
                            ) : each.file_name?.includes("wav") ? (
                              <MusicNote className="fs-18" />
                            ) : (
                              <DocumentScanner className="fs-18" />
                            )}
                          </span>
                        </p>
                      </p>
                    );
                  })
                )}
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <>
          <Skeleton width="100%" height={60} />
          <Skeleton width="100%" height={60} />
          <Skeleton width="100%" height={60} />
        </>
      )}
      {/* <Accordion>
        <AccordionSummary
          className="fw-700 fs-16"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={profileInfo ? false : true}
        >
          {profileInfo ? "Interaction 2" : <Skeleton width="100%" />}
        </AccordionSummary>
        <AccordionDetails>
          <p className="text-blue fs-5">
            <p>id= '3242345-545-2113-43fd-4342-cd'</p>
          </p>
          <p className="text-blue fs-5">
            <p>id= '3242345-545-2113-43fd-4342-cd'</p>
          </p>
          <p className="text-blue fs-5">
            <p>id= '3242345-545-2113-43fd-4342-cd'</p>
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          className="fw-700 fs-16"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={profileInfo ? false : true}
        >
          {profileInfo ? "Interaction 3" : <Skeleton width="100%" />}
        </AccordionSummary>
        <AccordionDetails>
          <p className="text-blue fs-5">
            <p>id= '3242345-545-2113-43fd-4342-cd'</p>
          </p>
          <p className="text-blue fs-5">
            <p>id= '3242345-545-2113-43fd-4342-cd'</p>
          </p>
          <p className="text-blue fs-5">
            <p>id= '3242345-545-2113-43fd-4342-cd'</p>
          </p>
        </AccordionDetails>
      </Accordion> */}
    </div>
  );
}
