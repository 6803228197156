import React, { useEffect, useState } from "react";
import GetData from "../../API/GetData";
import { CircularProgress, Fab } from "@mui/material";
import { set } from "date-fns";
import moment from "moment";
import { EditNote, MusicNote } from "@mui/icons-material";
import { Link } from "react-router-dom";

export default function TableInsideComponent(props) {
  const {
    startDate,
    endDate,
    facilityName,
    column,
    setFileDataCount,
    setCommentsDataCount,
    setFormEnteredDataCount,
    setRecordingsDataCount,
    setconsentFormDataCount,
    setTestReportDataCount,
    setpatientId,
    searchWord,
  } = props;
  const [fileData, setFileData] = useState([]);
  const [consentFormData, setconsentFormData] = useState([]);
  const [recordingsData, setRecordingsData] = useState([]);
  const [formEnteredData, setFormEnteredData] = useState([]);
  const [commentsData, setCommentsData] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const [testReportData, setTestReportData] = useState([]);

  useEffect(() => {
    if ((!startDate && !endDate) || (startDate && endDate)) {
      setIsloading(true);
      GetData.AllVisitsChecklistByColumn(
        column,
        facilityName,
        startDate,
        endDate
      )
        .then((value) => {
          setIsloading(false);
          if (value) {
            const data = value.data;
            if (column === "file") {
              setFileData(data);
              setFileDataCount(data?.length);
            } else if (column === "form_updated") {
              setFormEnteredData(data);
              setFormEnteredDataCount(data?.length);
            } else if (column === "recording") {
              setRecordingsData(data);
              setRecordingsDataCount(data?.length);
            } else if (column === "comment") {
              setCommentsData(data);
              setCommentsDataCount(data?.length);
            } else if (column === "test_report") {
              setTestReportData(data);
              setTestReportDataCount(data?.length);
            } else if (column === "consent") {
              setconsentFormData(data);
              setconsentFormDataCount(data?.length);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [startDate, endDate, facilityName, column]);

  const data_to_map =
    column === "file"
      ? fileData
      : column === "form_updated"
      ? formEnteredData
      : column === "recording"
      ? recordingsData
      : column === "comment"
      ? commentsData
      : column === "test_report"
      ? testReportData
      : column === "consent" && consentFormData;

  const filteredData = data_to_map.filter((data) =>
    Object.values(data).some((value) =>
      value?.toString().toLowerCase().includes(searchWord.toLowerCase())
    )
  );

  return isLoading ? (
    <div className="d-flex w-100 h-250">
      <div className="mx-auto">
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div
      className={`w-100 overflow-auto ${data_to_map ? "grid-4" : "row"} h-250`}
    >
      {filteredData ? (
        filteredData.map((data, index) => {
          return (
            <div
              key={index}
              className="info-card p-3 m-2 bg-white rounded-3 fs-14 position-relative"
            >
              <div className="text-end position-absolute end-0 bottom-0 p-4">
                {(column === "form_updated" || column === "comment") && (
                  <Link to={`/Form/${data.visit_id}`}>
                    <button className="btn btn-primary">
                      Update <EditNote className="fs-1" />
                    </button>
                  </Link>
                )}
              </div>
              <div className="w-100 p-2">
                {Object.entries(data).map(([key, value, index]) => {
                  return (
                    <p className="mb-1 fs-12" key={index}>
                      <span className="text-primry fw-bold mx-2"> {key} </span>:
                      <span className="mx-2">
                        {key === "created_at"
                          ? moment(value).format("YYYY-MM-DD")
                          : value}
                      </span>
                    </p>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <div className="h-250 w-100">
          <p className="text-center mx-auto text-warning fs-18 fw-bold">
            No Data Found
          </p>
        </div>
      )}
    </div>
  );
}
