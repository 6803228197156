import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import HomePageApi from "../API/HomePageApi";
import { IconButton, Skeleton } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { set } from "date-fns";
import { da } from "date-fns/locale";
import { Label, Restore } from "@mui/icons-material";

export default function DailyCaseTracker(props) {
  const {
    cardHeight,
    id,
    chartHeight,
    heading,
    y_title,
    colColors,
    ApiData,
    endDate,
    startDate,
    setStartDate,
    filter_by,
    setfilter_by,
    setisLoading,
    isLoading,
    setApiData,
    facilityId,
    medicalStatus,
    setGraphBy,
    graphBy,
  } = props;

  const [xCategories, setxCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (ApiData) {
      const sortedCategories = Object.keys(ApiData).sort();
      setxCategories(sortedCategories);

      const labels = Array.from(
        new Set(Object.values(ApiData).flatMap((entry) => Object.keys(entry)))
      );

      setSeries(
        labels.map((label) => ({
          name: label === "Pulmonary TB" ? "TB" : label,
          data: sortedCategories.map((date) => ({
            x: date,
            y: ApiData[date][label] || 0,
          })),
          color:
            label === "Pulmonary TB" || label === "TB"
              ? "#df9c45"
              : label === "Asthma"
              ? "#49beff"
              : label === "COPD"
              ? "#5d87ff"
              : label === "Healthy"
              ? "#13deb9"
              : label === null
              ? "#d5239e"
              : label === "Others"
              ? "#045c9b"
              : "",
        }))
      );
    }
  }, [ApiData, filter_by]);

  const handleDataPointSelection = (dataPointIndex) => {
    setisLoading(true);
    setxCategories([]);
    setApiData([]);
    if (filter_by === "year") {
      setfilter_by("month");
    } else if (filter_by === "month") {
      setfilter_by("day");
    } else if (filter_by === "day") {
      setfilter_by("year");
    }
    setStartDate(moment(xCategories[dataPointIndex]).format("YYYY-MM-DD"));
  };

  const options = {
    chart: {
      type: "bar",
      height: 450,
      stacked: true,
      events: {
        dataPointSelection: (event, chartContext, config) => {
          handleDataPointSelection(config.dataPointIndex);
        },
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
        autoScaleXaxis: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: "10",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        // columnWidth: "20",
        borderRadiusWhenStacked: "all",
      },
    },
    // title: {
    //   text: heading,
    //   align: "left",
    //   style: {
    //     fontSize: "24px",
    //     fontWeight: "700",
    //     fontFamily: "poppins",
    //     color: "#000000",
    //   },
    // },
    //colors: ,
    xaxis: {
      // type: "datetime",
      categories: xCategories,
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        tickAmount: 4,
      },
    },
    yaxis: {
      title: {
        text: y_title,
        style: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
      },
    },
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "top",
      offsetY: -30,
      horizontalAlign: "right",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      markers: {
        radius: 10,
        offsetY: 2,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div className="position-relative" key={id} style={{ height: cardHeight }}>
      <IconButton
        className="reset-btn p-1"
        onClick={() => {
          setfilter_by("year");
          setStartDate(moment().format("YYYY-MM-DD"));
        }}
      >
        <Restore className="fs-1" />
      </IconButton>
      <div id="chart">
        <Fade delay={1e2}>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={chartHeight || 350}
          />
        </Fade>
      </div>
    </div>
  );
}
// import React, { useEffect, useState } from "react";
// import moment from "moment";
// import axios from "axios";
// import ReactApexChart from "react-apexcharts";
// import HomePageApi from "../API/HomePageApi";
// import { Skeleton } from "@mui/material";
// import { Fade } from "react-awesome-reveal";
// import { set } from "date-fns";
// import { da } from "date-fns/locale";
// export default function DailyCaseTracker(props) {
//   const {
//     cardHeight,
//     id,
//     chartHeight,
//     heading,
//     y_title,
//     colColors,
//     ApiData,
//     endDate,
//     startDate,
//     setStartDate,
//     filter_by,
//     setfilter_by,
//     setisLoading,
//     isLoading,
//     setApiData,
//     facilityId,
//     medicalStatus,
//   } = props;
//   const [xCategories, setxCategories] = useState([]);
//   const [series, setSeries] = useState([]);
//   useEffect(() => {
//     if (ApiData) {
//       setxCategories(Object.keys(ApiData));
//       const labels = Array.from(
//         new Set(Object.values(ApiData).flatMap((entry) => Object.keys(entry)))
//       );
//       setSeries(
//         labels.map((label) => ({
//           name: label,
//           data: Object.entries(ApiData).map(([date, counts]) => ({
//             x: date,
//             y: counts[label] || 0,
//           })),
//         }))
//       );
//     }
//   }, [ApiData, filter_by]);
//   const handleDataPointSelection = (dataPointIndex) => {
//     setisLoading(true);
//     setxCategories([]);
//     setApiData([]);
//     setfilter_by(
//       filter_by === "year" ? "month" : filter_by === "month" ? "day" : "Year"
//     );
//     setStartDate(moment(xCategories[dataPointIndex]).format("YYYY-MM-DD"));
//   };
//   const options = {
//     chart: {
//       type: "bar",
//       height: 450,
//       stacked: true,
//       events: {
//         dataPointSelection: (event, chartContext, config) => {
//           handleDataPointSelection(config.dataPointIndex);
//         },
//       },
//       toolbar: {
//         show: false,
//       },
//       zoom: {
//         enabled: false,
//         autoScaleXaxis: false,
//       },
//     },
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           plotOptions: {
//             bar: {
//               borderRadius: 0,
//               columnWidth: "10",
//             },
//           },
//         },
//       },
//     ],
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         borderRadius: 2,
//         columnWidth: "20",
//         borderRadiusWhenStacked: "all",
//       },
//     },
//     title: {
//       text: heading,
//       align: "left",
//       style: {
//         fontSize: "24px",
//         fontWeight: "700",
//         fontFamily: "poppins",
//         color: "#000000",
//       },
//     },
//     colors: colColors,
//     xaxis: {
//       type: "datetime",
//       categories: xCategories,
//       labels: {
//         style: {
//           fontSize: "12px",
//           fontFamily: "Poppins",
//           fontWeight: 400,
//         },
//         tickAmount: 4,
//       },
//     },
//     yaxis: {
//       title: {
//         text: y_title,
//         style: {
//           fontSize: "12px",
//           fontFamily: "Poppins",
//           fontWeight: 400,
//         },
//       },
//     },
//     legend: {
//       show: true,
//       showForSingleSeries: false,
//       showForNullSeries: true,
//       showForZeroSeries: true,
//       position: "top",
//       offsetY: -30,
//       horizontalAlign: "right",
//       fontSize: "12px",
//       fontFamily: "Poppins",
//       fontWeight: 400,
//       markers: {
//         radius: 10,
//         offsetY: 2,
//       },
//     },
//     fill: {
//       opacity: 1,
//     },
//   };
//   return (
//     <div className="graph-cards" key={id} style={{ height: cardHeight }}>
//       <div id="chart">
//         <Fade delay={1e2}>
//           <ReactApexChart
//             options={options}
//             series={series}
//             type="bar"
//             height={chartHeight || 350}
//           />
//         </Fade>
//       </div>
//     </div>
//   );
// }
