import { Snackbar } from "@mui/material";
import axios from "axios";

class GetData {
  constructor() {
    this.state = {
      open: false,
    };
  }

  GetFormData = (visit_id) => {
    const res = async () => {
      const resp = await axios
        .get(`/user/get/${visit_id}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  PostFormData = async (Payload) => {
    const for_approval = window.location.href.includes("updatedform")
      ? true
      : false;
    try {
      const resp = await axios.post(
        `/info/user?for_approval=${for_approval}`,
        Payload
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: error.response.data.detail };
    }
  };

  ApproveVisitComment = async (concatinateComent, visit_Id, first_approval) => {
    const Payload = { comment: concatinateComent };
    try {
      const resp = await axios.post(
        `/visit/${visit_Id}/comments?first_approval=${first_approval}`,
        Payload
      );
      return resp;
    } catch (error) {
      return { error: error.response.data.detail };
    }
  };

  GetCommentOnFormById = async (visit_id) => {
    try {
      const resp = await axios.get(
        `/visit/${visit_id}/comments?first_approval=true`
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: error.response.data.detail };
    }
  };

  GetAllCommentOnForm = async () => {
    try {
      const resp = await axios.get(`/visit/comments?first_approval=true`);
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: error.response.data.detail };
    }
  };

  CompareTable = async (visit_id, first_approval) => {
    try {
      const resp = await axios.get(
        `/visit/approve/${visit_id}?first_approval=${first_approval}`
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        console.log(error);
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: "Something went wrong" };
    }
  };

  ApproveList = async (first_approval) => {
    try {
      const resp = await axios.get(
        `/visit/approve?first_approval=${first_approval}`
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: "Something went wrong" };
    }
  };

  Approval = async (visit_id, first_approval, delete_comment) => {
    try {
      const resp = await axios.post(
        `/info/user?for_approval=true&first_approval=${first_approval}${
          delete_comment
            ? `&delete_comment=${true}`
            : `&delete_comment=${false}`
        }`,
        {
          visit_id,
        }
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: "Something went wrong" };
    }
  };

  Rejection = async (visit_Id, first_approval) => {
    try {
      const resp = await axios.delete(
        `/visit/approve/${visit_Id}?first_approval=${first_approval}`
      );
      return resp;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        return {
          error: `In ${error.response.data.detail[0].loc[0]}, ${error.response.data.detail[0].loc[1]} ${error.response.data.detail[0].msg}`,
        };
      } else return { error: "Something went wrong" };
    }
  };

  // AllPatientsList = () => {
  //   const res = async () => {
  //     const resp = await axios.get("/info/patient/get").catch(function (error) {
  //       console.log(error);
  //       if (error.response.request.statusText == "Unauthorized") {
  //         localStorage.clear();
  //         window.location.reload();
  //       }
  //     });
  //     return resp;
  //   };
  //   return res();
  // };

  // AllPatientsList = ( onDataReceived, onError) => {
  //   const xhr = new XMLHttpRequest();

  //   xhr.open("GET", "http://192.168.22.148:8080/info/patient/get", true);
  //   xhr.setRequestHeader("Content-Type", "application/json");
  //   xhr.setRequestHeader("Authorization", `Bearer ${authToken}`);

  //   let allPatients = [];
  //   let buffer = ''; // Buffer to hold incomplete JSON chunks
  //   xhr.onreadystatechange = () => {
  //     if (xhr.readyState === XMLHttpRequest.LOADING || xhr.readyState === XMLHttpRequest.DONE) {
  //       buffer += xhr.responseText;

  //       let start = 0;
  //       let end = buffer.indexOf('\n', start);

  //       while (end !== -1) {
  //         const jsonString = buffer.substring(start, end).trim();

  //         if (jsonString) {
  //           try {
  //             const newPatient = JSON.parse(jsonString);
  //             allPatients.push(newPatient);
  //             onDataReceived([...allPatients]);
  //           } catch (error) {
  //             console.error("Error parsing JSON:", error, jsonString);
  //           }
  //         }

  //         start = end + 1;
  //         end = buffer.indexOf('\n', start);
  //       }

  //       buffer = buffer.substring(start); // Keep the remaining incomplete chunk in buffer
  //     }

  //     if (xhr.readyState === XMLHttpRequest.DONE) {
  //       if (xhr.status !== 200) {
  //         onError(new Error(`Request failed with status ${xhr.status}`));
  //       }
  //     }
  //   };

  //   xhr.onerror = () => onError(new Error("Network error"));
  //   xhr.send();
  // };

  AllVisitsChecklistByColumn = (column, facilityName, startDate, endDate) => {
    const addDates =
      startDate && endDate
        ? `&start_date=${startDate}&end_date=${endDate}`
        : "";
    const adddFacility = facilityName ? `&facility=${facilityName}` : "";
    const res = async () => {
      const resp = await axios
        .get(`/visit/info?column=${column}${adddFacility}${addDates}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  AllVisitsChecklist = () => {
    const res = async () => {
      const resp = await axios.get("/visit/info/2").catch(function (error) {
        console.log(error);
        if (error.response.request.statusText == "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });
      return resp;
    };
    return res();
  };

  AllPatientsListByDate = (createdAtDate) => {
    const res = async () => {
      const resp = await axios
        .get(`/info/patient/get?date_=${createdAtDate}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  GetVisitsById = (patientId) => {
    const res = async () => {
      const resp = await axios
        .get(`/visit/get/${patientId}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  GetRecordingsById = (visit_id) => {
    const res = async () => {
      const resp = await axios
        .get(`/record/get?v_id=${visit_id}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  // GetImageById = (fetchImgId) => {
  //   if (fetchImgId) {
  //     const res = async () => {
  //       const resp = await axios
  //         .get(`/model/panns/${fetchImgId}`)
  //         .catch(function (error) {
  //           console.log(error);
  //           if (error.response.request.statusText == "Unauthorized") {
  //             localStorage.clear();
  //             window.location.reload();
  //           }
  //         });
  //       return resp;
  //     };
  //     return res();
  //   }
  // };

  GetRecordingByUrl = (fetchUrl) => {
    const res = async () => {
      const resp = await axios.get(fetchUrl).catch(function (error) {
        console.log(error);
        if (error.response.request.statusText == "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });
      return resp;
    };
    return res();
  };

  PostAnnotationById = (Payload) => {
    const res = async () => {
      const resp = await axios
        .post("/record/annotate", Payload)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };
  GetAllAgentsNew = () => {
    const res = async () => {
      const resp = await axios.get("/facility/all/new").catch(function (error) {
        console.log(error);
        if (error.response.request.statusText == "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });

      return resp;
    };
    return res();
  };
  GetAllAgents = () => {
    const res = async () => {
      const resp = await axios.get("/facility/all").catch(function (error) {
        console.log(error);
        if (error.response.request.statusText == "Unauthorized") {
          localStorage.clear();
          window.location.reload();
        }
      });

      return resp;
    };
    return res();
  };
  GetPatientStat = (queryParam) => {
    const res = async () => {
      const resp = await axios
        .get(`/dash/patient/stat?${queryParam}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });

      return resp;
    };
    return res();
  };
  GetCardsData = (queryParam) => {
    const res = async () => {
      const resp = await axios
        .get(`/dash/card/stat?${queryParam}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };
  PatAgeChart = (queryParam) => {
    const res = async () => {
      const resp = await axios
        .get(`/dash/age/stat?${queryParam}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };
  PatStatsChartTwo = (queryParam) => {
    const res = async () => {
      const resp = await axios
        .get(`/dash/record/stat?${queryParam}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };
  FeatureChartOne = (queryParam, featureName) => {
    const res = async () => {
      const resp = await axios
        .get(`/dash/feature/${featureName}/stat?${queryParam}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };
  FeatureChartTwo = (queryParam, featureNameTwo) => {
    const res = async () => {
      const resp = await axios
        .get(`/dash/record/${featureNameTwo}/stat?${queryParam}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };
}
export default new GetData();
