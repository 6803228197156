import React, { useState, useEffect } from "react";
import TableComponent from "../../Atoms/TableComponent";
import AdminData from "../../API/AdminData";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  padding: 20,
  borderRadius: "8px",
};

export default function FacilityTable(props) {
  const { successMessage, ErrorMessage, setsuccessMessage, setErrorMessage } =
    props;

  const [facilityList, setFacilityList] = useState([]);
  const [sendingReq, setsendingReq] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [AddUserModal, setAddUserModal] = useState(false);
  const [facility_id, setfacility_id] = useState();
  const [forUpdate, setforUpdate] = useState(false);
  const [facility_name, setfacility_name] = useState();
  const [isActive, setisActive] = useState();

  useEffect(() => {
    setsendingReq(true);
    AdminData.GetFacilityDetail().then((res) => {
      res = res.data;
      setsendingReq(false);
      for (let i in res) {
        res[i].sNo = Number(i) + 1;
      }
      setFacilityList(res);
    });
  }, [refreshTable]);

  const handleSubmit = async (e) => {
    setsendingReq(true);
    const payload = {
      name: facility_name,
      is_active: isActive,
    };
    const res = forUpdate
      ? AdminData.UpdateFacility(facility_id, payload)
      : AdminData.AddNewFacility(payload);
    res
      .then((value) => {
        if (value.error) {
          setsendingReq(false);
          setErrorMessage(`Error: ${value.error}`);
        } else {
          setsendingReq(false);
          setAllStatesNull();
          setAddUserModal(false);
          setsuccessMessage(value.data.detail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setrefreshTable(!refreshTable);
    e.preventDefault();
  };

  const setAllStatesNull = () => {
    setfacility_id();
    setfacility_name("");
    setforUpdate(false);
  };

  const columnDefs = [
    {
      headerName: "S.No",
      field: "sNo",
      sortable: true,
    },
    {
      headerName: "Facility name",
      field: "name",
      sortable: true,
    },
    {
      headerName: "Facility id",
      field: "id",
      sortable: true,
    },
    {
      headerName: "Status",
      field: "is_active",
      cellRenderer: (params) =>
        params.value ? (
          <p className="fs-14 Lato text-success">Active</p>
        ) : (
          <p className="fs-14 Lato text-danger">Inactive</p>
        ),
      sortable: true,
    },
    {
      headerName: "Action",
      field: "sNo",
      sortable: true,
      minWidth: 70,
      cellRenderer: function (params) {
        return (
          <Stack direction="row" spacing={2}>
            <IconButton>
              <Tooltip title="Delete Facility">
                <Edit
                  onClick={() => {
                    setforUpdate(true);
                    setfacility_id(params.data.id);
                    setfacility_name(params.data.name);
                    setisActive(params.data.is_active);
                    setAddUserModal(true);
                  }}
                  className="mx-auto fs-1 text-primary"
                />
              </Tooltip>
            </IconButton>
            {params.data.is_active ? (
              <IconButton>
                <Tooltip title="Delete Facility">
                  <Delete
                    onClick={() => {
                      const facility_id = params.data.id;
                      if (facility_id) {
                        const res = AdminData.DeleteFacility(facility_id);
                        res.then((value) => {
                          setsuccessMessage(
                            `Facility ${facility_id} has been removed successfully from your List`
                          );
                          setrefreshTable(!refreshTable);
                        });
                      }
                    }}
                    className="mx-auto fs-1 text-danger"
                  />
                </Tooltip>
              </IconButton>
            ) : null}
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <TableComponent
        value={"facilityList"}
        Data={facilityList}
        columnDefs={columnDefs}
        floatingFilter={true}
        buttonText="New Facility"
        setAddUserModal={setAddUserModal}
        sendingReq={sendingReq}
        isNotCounting={false}
      />
      <Modal
        open={AddUserModal}
        onClose={() => {
          setAllStatesNull();
          setAddUserModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-light col-12 col-md-7" style={style}>
          <div className="h-100 w-100 d-flex">
            <form
              className="mx-auto my-auto w-100 w-md-75"
              type="submit"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                Add New Facility
              </p>
              <div className="text-start mb-4">
                <input
                  className="mb-1"
                  value={facility_name}
                  type="text"
                  name="facility_name"
                  onChange={(e) => setfacility_name(e.target.value)}
                  placeholder="Facility Name"
                />
              </div>
              {forUpdate && (
                <div className="form-group mb-4">
                  <label className="text-nowrap" htmlFor="facility_id">
                    Activate Facility:
                  </label>
                  <select
                    className="form-control form-control-lg"
                    id="facility_id"
                    value={isActive}
                    onChange={(e) => {
                      setisActive(e.target.value);
                    }}
                  >
                    <option value="">choose</option>
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </select>
                </div>
              )}
              <button
                type="submit"
                className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45"
              >
                {sendingReq ? (
                  <CircularProgress color="inherit" size={22} />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        onClose={() => {
          setsuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "error" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
