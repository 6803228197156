import React, { useEffect, useState } from "react";
import GetData from "../../API/GetData";
import moment from "moment";
import { Link, useNavigate, useParams } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { CircleRounded, Report } from "@mui/icons-material";
import { Alert, Chip } from "@mui/material";

export default function Index() {
  const [getForm, setgetForm] = useState([]);
  const [ApiData, setApiData] = useState([]);
  const [sendingReq, setsendingReq] = useState(false);
  const [sendErrorToField, setsendErrorToField] = useState(false);
  const [open, setOpen] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [shouldFormSubmit, setshouldFormSubmit] = useState(false);
  const [updateForm, setupdateForm] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [list, setlist] = useState([]);
  const { visit_id } = useParams();
  const history = useNavigate();
  const [isCoughingOldField, setisCoughingOldField] = useState(false);
  const [commentOnFields, setcommentOnFields] = useState("");
  const [commentedBy, setcommentedBy] = useState("");
  const [CommentsList, setCommentsList] = useState([]);
  const [commentsArrayRefresh, setCommentsArrayRefresh] = useState(false);
  const [fileCount, setfileCount] = useState(0);
  const [coughingCount, setcoughingCount] = useState(0);
  const [breathingCount, setbreathingCount] = useState(0);
  const [testReportCount, settestReportCount] = useState(0);
  const [formCount, setformCount] = useState(0);
  const [consentFormCount, setconsentFormCount] = useState(0);

  const onlyarrayComment =
    window.location.href.includes("Form") && commentOnFields.split("||");

  useEffect(() => {
    let first_approval = false;
    if (visit_id) {
      if (window.location.href.includes("updatedform")) {
        const res = GetData.CompareTable(visit_id, (first_approval = false));
        res.then((value) => {
          setcommentOnFields(value.data.comment || "No Comments on this form");
          setgetForm({
            ...value.data.updated,
            initial_diagnose: value.data.updated.initial_diagnose
              ? moment(value.data.updated.initial_diagnose).format("YYYY-MM-DD")
              : null,
            test_date: value.data.updated.test_date
              ? moment(value.data.updated.test_date).format("YYYY-MM-DD")
              : null,
            dob: value.data.updated.dob
              ? moment(value.data.updated.dob).format("YYYY-MM-DD")
              : null,
          });
          setApiData({
            ...value.data.updated,
            initial_diagnose: value.data.initial_diagnose
              ? moment(value.data.initial_diagnose).format("YYYY-MM-DD")
              : null,
            test_date: value.data.test_date
              ? moment(value.data.test_date).format("YYYY-MM-DD")
              : null,
            dob: value.data.dob
              ? moment(value.data.dob).format("YYYY-MM-DD")
              : null,
          });
        });
      } else if (window.location.href.includes("onfirstupdated")) {
        const res = GetData.CompareTable(visit_id, (first_approval = true));
        res.then((value) => {
          setgetForm({
            ...value.data.updated,
            initial_diagnose: value.data.updated.initial_diagnose
              ? moment(value.data.updated.initial_diagnose).format("YYYY-MM-DD")
              : null,
            test_date: value.data.updated.test_date
              ? moment(value.data.updated.test_date).format("YYYY-MM-DD")
              : null,
            dob: value.data.updated.dob
              ? moment(value.data.updated.dob).format("YYYY-MM-DD")
              : null,
          });
          setApiData({
            ...value.data.updated,
            initial_diagnose: value.data.initial_diagnose
              ? moment(value.data.initial_diagnose).format("YYYY-MM-DD")
              : null,
            test_date: value.data.test_date
              ? moment(value.data.test_date).format("YYYY-MM-DD")
              : null,
            dob: value.data.dob
              ? moment(value.data.dob).format("YYYY-MM-DD")
              : null,
          });
        });
      } else {
        const res = GetData.GetFormData(visit_id);
        res.then((value) => {
          setgetForm({
            ...value.data,
            initial_diagnose: value.data.initial_diagnose
              ? moment(value.data.initial_diagnose).format("YYYY-MM-DD")
              : null,
            test_date: value.data.test_date
              ? moment(value.data.test_date).format("YYYY-MM-DD")
              : null,
            dob: value.data.dob
              ? moment(value.data.dob).format("YYYY-MM-DD")
              : null,
          });
          setApiData({
            ...value.data,
            initial_diagnose: value.data.initial_diagnose
              ? moment(value.data.initial_diagnose).format("YYYY-MM-DD")
              : null,
            test_date: value.data.test_date
              ? moment(value.data.test_date).format("YYYY-MM-DD")
              : null,
            dob: value.data.dob
              ? moment(value.data.dob).format("YYYY-MM-DD")
              : null,
          });
        });
      }
    }
  }, [visit_id]);

  useEffect(() => {
    setisLoading(true);
    const res = GetData.GetRecordingsById(visit_id);
    res.then((value) => {
      let listarray = [];
      value.data.map((each) => {
        if (each.file_name.includes(".jpg")) {
          listarray.push(each);
        } else setlist([]);
      });
      setlist(listarray);
    });
    setisLoading(false);
  }, [visit_id]);

  useEffect(() => {
    const res = GetData.GetCommentOnFormById(visit_id);
    res.then((value) => {
      setcommentedBy(value.data.comment_added_by);
      setcommentOnFields(value.data.comment || "No Comments to show");
    });
    GetData.GetRecordingsById(visit_id).then((value) => {
      if (value !== undefined) {
        const data = value.data;
        const getCountByType = (type) => {
          return data?.filter((label) => label.type === type).length;
        };
        setfileCount(getCountByType("file"));
        setcoughingCount(getCountByType("coughing"));
        setbreathingCount(getCountByType("breathing"));
        settestReportCount(getCountByType("test-report"));
        setformCount(getCountByType("form"));
        setconsentFormCount(getCountByType("consent-form"));
      }
    });
  }, [visit_id]);

  useEffect(() => {
    const res = GetData.GetAllCommentOnForm();
    res.then((value) => {
      setCommentsList(value.data || []);
    });
  }, [visit_id, commentsArrayRefresh]);

  const handleForm = async (e) => {
    const Payload = {
      ...getForm,
      visit_id: visit_id,
      p_id: getForm.p_id,
      medical_conditions:
        getForm.medical_conditions &&
        getForm.medical_conditions.includes("none")
          ? null
          : [...new Set(getForm.medical_conditions)],
      family_medical_history:
        getForm.family_medical_history &&
        getForm.family_medical_history.includes("none")
          ? null
          : [...new Set(getForm.family_medical_history)],
      initial_diagnose:
        getForm.initial_diagnose == null || getForm.initial_diagnose == ""
          ? null
          : moment(getForm.initial_diagnose).format("YYYY-MM-DD"),
      test_date:
        getForm.test_date == null || getForm.test_date == ""
          ? null
          : moment(getForm.test_date).format("YYYY-MM-DD"),
      dob:
        getForm.dob == null || getForm.dob == ""
          ? null
          : moment(getForm.dob).format("YYYY-MM-DD"),
    };
    setsendingReq(true);
    const res = GetData.PostFormData(Payload);

    res
      .then((value) => {
        if (value.error) {
          setsendingReq(false);
          setErrorMessage(`Error: ${value.error}`);
        } else {
          history(`/form/${visit_id}`);
          setOpen(true);
          setsuccessMessage("Form was submitted successfully");
          setcommentedBy("");
          setcommentOnFields("");
          setCommentsArrayRefresh(!commentsArrayRefresh);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    e.preventDefault();
  };

  const handleMedicalConditions = (event) => {
    const { value, checked } = event.target;
    setgetForm((prevForm) => ({
      ...prevForm,
      medical_conditions: checked
        ? [...(prevForm.medical_conditions || []), value]
        : (prevForm.medical_conditions || [])?.filter((val) => val !== value),
    }));
  };

  const handleFamilyHistory = (event) => {
    const { value, checked } = event.target;
    setgetForm((prevForm) => ({
      ...prevForm,
      family_medical_history: checked
        ? [...(prevForm.family_medical_history || []), value]
        : (prevForm.family_medical_history || [])?.filter(
            (val) => val !== value
          ),
    }));
  };

  const handleClose = () => {
    setOpen(false);
    setsuccessMessage("");
  };

  const renderChip = (label, count) => {
    return (
      count !== 0 && (
        <Chip
          variant="outlined"
          className="fs-16 me-2 mb-1 rounded-4"
          label={`${label}: ${count}`}
          sx={{
            fontWeight: "400",
            color: "#175CD3",
            borderColor: "#175CD3",
            backgroundColor: "#EFF8FF",
          }}
        />
      )
    );
  };

  return (
    <div className="row container mx-auto pt-3">
      <h1 className="mt-3 mb-2 text-blue">
        Patient Information Gathering (
        <span
          className="text-info"
          type="button"
          onClick={() => setisCoughingOldField(!isCoughingOldField)}
        >
          {isCoughingOldField ? "Old" : "Updated"}
        </span>
        ) Form
        <span className="fs-16 color-info mx-1">
          ({getForm.serial_number} | {getForm.visit_id} | {getForm.patient_type}
          | {getForm.date})
        </span>
        <span className="ms-3 text-nowrap overflow-scroll w-100">
          {renderChip("File", fileCount)}
          {renderChip("Coughing", coughingCount)}
          {renderChip("Breathing", breathingCount)}
          {renderChip("Test Report", testReportCount)}
          {renderChip("Form", formCount)}
          {renderChip("Consent Form", consentFormCount)}
          {/* {fileCount !== 0 && (
            <Chip
              variant="outlined"
              className="fs-16 me-2 mb-2 rounded-4"
              label={`File: ${fileCount || "_"}`}
              sx={{
                borderRadius: "16px",
                fontWeight: "400",
                color: "#175CD3",
                borderColor: "#175CD3",
                backgroundColor: "#EFF8FF",
              }}
            />
          )}
          {coughingCount !== 0 && (
            <Chip
              variant="outlined"
              className="fs-16 me-2 mb-2 rounded-4"
              label={`Coughing: ${coughingCount || "_"}`}
              sx={{
                borderRadius: "16px",
                fontWeight: "400",
                color: "#175CD3",
                borderColor: "#175CD3",
                backgroundColor: "#EFF8FF",
              }}
            />
          )}
          {breathingCount !== 0 && (
            <Chip
              variant="outlined"
              className="fs-16 me-2 mb-2 rounded-4"
              label={`Breathing: ${breathingCount || "_"}`}
              sx={{
                borderRadius: "16px",
                fontWeight: "400",
                color: "#175CD3",
                borderColor: "#175CD3",
                backgroundColor: "#EFF8FF",
              }}
            />
          )}
          {testReportCount !== 0 && (
            <Chip
              variant="outlined"
              className="fs-16 me-2 mb-2 rounded-4"
              label={`Test Report: ${testReportCount || "_"}`}
              sx={{
                borderRadius: "16px",
                fontWeight: "400",
                color: "#175CD3",
                borderColor: "#175CD3",
                backgroundColor: "#EFF8FF",
              }}
            />
          )}
          {formCount !== 0 && (
            <Chip
              variant="outlined"
              className="fs-16 me-2 mb-2 rounded-4"
              label={`Form: ${formCount || "_"}`}
              sx={{
                borderRadius: "16px",
                fontWeight: "400",
                color: "#175CD3",
                borderColor: "#175CD3",
                backgroundColor: "#EFF8FF",
              }}
            />
          )}
          {consentFormCount !== 0 && (
            <Chip
              variant="outlined"
              className="fs-16 mb-2 rounded-4"
              label={`Consent Form: ${consentFormCount || "_"}`}
              sx={{
                borderRadius: "16px",
                fontWeight: "400",
                color: "#175CD3",
                borderColor: "#175CD3",
                backgroundColor: "#EFF8FF",
              }}
            />
          )} */}
        </span>
        <p className="fs-16 p-1 mb-0">
          {list.length > 0
            ? list?.map((each) => {
                return (
                  <Chip
                    className="btn btn-outline-info fs-20 mx-2 mb-1"
                    onClick={() => window.open(each.url, "_blank")}
                    label={`${each?.type} ${moment(each?.created_at).format(
                      "YYYY-MM-DD"
                    )}`}
                  />
                );
              })
            : " No Related Images Found"}
        </p>
      </h1>
      {CommentsList && (
        <div
          // ${!window.location.href.includes("Form") && "d-none"}
          className={`row my-1 dash-cards p-1`}
        >
          <ul className="list-group list-group-horizontal p-4">
            {CommentsList?.map((each) => {
              return (
                <Link
                  className="mx-3 text-decoration-none cursor-pointer bg-secondary p-2 rounded-3 fs-16 text-white fw-bold"
                  // onClick={() => window.location.replace(`/Form/${each}`)}
                  to={`/Form/${each}`}
                >
                  <li
                    className={`list-group-item ${
                      window.location.href.includes(each) &&
                      "text-light bg-secondary"
                    }`}
                  >
                    Visit ID: {each}
                  </li>
                </Link>
              );
            })}
          </ul>
          {commentedBy && (
            <p className="text-primary fw-bold fs-16">
              Comment by{" "}
              <span className="text-dark mx-2">
                {commentedBy || "Undefined Username"}
              </span>{" "}
              Fields:
              <span className="text-dark mx-2">
                {onlyarrayComment[0] || "No Fields Selected"}
              </span>{" "}
              Comment :
              <span className="text-dark mx-2">
                {onlyarrayComment[1] || "No Comments to display"}
              </span>
            </p>
          )}
        </div>
      )}
      <form onSubmit={handleForm} method="POST" className="row g-4">
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="name">
            Name:
            {onlyarrayComment[0]?.includes("Name") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="text"
            placeholder="enter name"
            id="name"
            value={getForm.name}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, name: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="initial_diagnose">
            Date Of Diagnosis:
            {onlyarrayComment[0]?.includes("Diagnosis-Date") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="date"
            placeholder="select date"
            id="initial_diagnose"
            value={getForm.initial_diagnose || ""}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, initial_diagnose: e.target.value });
            }}
          />
          <p>{getForm.initial_diagnose}</p>
          {sendErrorToField && (
            <p className="small text-danger fw-bold">
              Diagnosis date is greater than Created at Date
              {moment(getForm.created_at).format("YYYY-MM-DD")}
            </p>
          )}
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="cough">
            Cough:
            {onlyarrayComment[0]?.includes("cough") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="cough"
            value={getForm.cough}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, cough: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="coughingFor">
            Coughing for:
            {onlyarrayComment[0]?.includes("Coughing For") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className={`form-control form-control-lg ${
              isCoughingOldField && "d-none"
            }`}
            type="number"
            pattern="/[^.e]/"
            placeholder="enter days"
            id="coughingFor"
            value={getForm.cough_frq}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, cough_frq: e.target.value });
            }}
          />
          <select
            className={`form-control form-control-lg ${
              !isCoughingOldField && "d-none"
            }`}
            id="cough_frq"
            value={getForm.cough_frq}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, cough_frq: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>More than a month</option>
            <option>More than a week</option>
            <option>Less than a week</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="coughing_blood">
            Blood In Cough:
            {onlyarrayComment[0]?.includes("Blood In Cough") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="coughing_blood"
            value={getForm.coughing_blood}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, coughing_blood: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="fever">
            Fever:
            {onlyarrayComment[0]?.includes("Fever") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="fever"
            value={getForm.fever}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, fever: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="fever_for">
            Fever for:
            {onlyarrayComment[0]?.includes("Fever For") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            disabled={getForm.fever === "No" && true}
            type="number"
            pattern="/[^.e]/"
            placeholder="enter days"
            id="fever_for"
            value={getForm.fever_for}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, fever_for: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="fever_level">
            Fever Level:
            {onlyarrayComment[0]?.includes("Fever Level") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            disabled={getForm.fever === "No" && true}
            id="fever_level"
            value={getForm.fever_level}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, fever_level: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>High &gt; 100</option>
            <option>Low &lt; 100</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="fever_time">
            Fever Time:
            {onlyarrayComment[0]?.includes("Fever Time") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            disabled={getForm.fever === "No" && true}
            id="fever_time"
            value={getForm.fever_time}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, fever_time: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Both</option>
            <option>Morning</option>
            <option>Evening</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="nights_sweats">
            Night Sweats:
            {onlyarrayComment[0]?.includes("Night Sweats") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="nights_sweats"
            value={getForm.nights_sweats}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, nights_sweats: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="wheezing">
            Wheezing Sound:
            {onlyarrayComment[0]?.includes("Wheezing Sound") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="wheezing"
            value={getForm.wheezing}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, wheezing: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="shortness_of_breath">
            Shortness of breath:
            {onlyarrayComment[0]?.includes("Shortness Of Breath") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="shortness_of_breath"
            value={getForm.shortness_of_breath}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, shortness_of_breath: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="shortness_of_breath_for">
            Shortness of breath for:
            {onlyarrayComment[0]?.includes("Shortness Of Breath For") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className={`form-control form-control-lg ${
              isCoughingOldField && "d-none"
            }`}
            type="number"
            pattern="/[^.e]/"
            placeholder="enter days"
            id="shortness_of_breath_for"
            value={getForm.shortness_of_breath_for}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({
                ...getForm,
                shortness_of_breath_for: e.target.value,
              });
            }}
          />
          <select
            className={`form-control form-control-lg ${
              !isCoughingOldField && "d-none"
            }`}
            id="shortness_of_breath_for"
            value={getForm.shortness_of_breath_for}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({
                ...getForm,
                shortness_of_breath_for: e.target.value,
              });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>More than a month</option>
            <option>More than a week</option>
            <option>Less than a week</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="weight_loss">
            Weight Loss:
            {onlyarrayComment[0]?.includes("Weight-Loss") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="weight_loss"
            value={getForm.weight_loss}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, weight_loss: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="loss_appetite">
            Loss of appetite:
            {onlyarrayComment[0]?.includes("Loss Of Appetite") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="loss_appetite"
            value={getForm.loss_appetite}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, loss_appetite: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="smoking_history">
            Smoking history:
            {onlyarrayComment[0]?.includes("Smoking History") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="smoking_history"
            value={getForm.smoking_history}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, smoking_history: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option valve="Smoker">Smoker</option>
            <option valve="Ex-Smoker">Ex-Smoker</option>
            <option valve="Non-Smoker">Non-Smoker</option>
            <option valve="Passive-Smoker">Passive Smoker</option>
          </select>
        </div>
        <div className="form-group col-md-8">
          <label className="text-nowrap col-3" htmlFor="MedicalHistory">
            Medical Conditions:
            {onlyarrayComment[0]?.includes("Medical Conditions") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <div className="form-group ms-4 d-md-flex justify-content-between">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="HTN/BP"
                value="HTN/BP"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("HTN/BP")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalHTNBP"
              >
                HTN/BP
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Asthma"
                value="Asthma"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("Asthma")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalAsthma"
              >
                Asthma
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="COPD"
                value="COPD"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("COPD")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalCOPD"
              >
                COPD
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Diabetes"
                value="Diabetes"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("Diabetes")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalDiabetes"
              >
                Diabetes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Hepatitis B/C"
                value="Hepatitis B/C"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("Hepatitis B/C")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalHepatitisBC"
              >
                Hepatitis B/C
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Heart disease"
                value="Heart disease"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("Heart disease")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalHeartdisease"
              >
                Heart disease
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Others"
                value="Others"
                checked={
                  !getForm.medical_conditions?.includes("none") &&
                  getForm.medical_conditions?.includes("Others")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalOthers"
              >
                Others
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="none"
                value="none"
                required={
                  getForm.medical_conditions == null ||
                  getForm.medical_conditions?.length < 1
                    ? true
                    : false
                }
                checked={
                  getForm.medical_conditions == null ||
                  getForm.medical_conditions == [] ||
                  getForm.medical_conditions == "" ||
                  getForm.medical_conditions?.includes("none")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleMedicalConditions(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="knownMedicalnone"
              >
                None
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-8">
          <label className="text-nowrap col-3" htmlFor="familyMedicalHistory">
            Family Medical History:
            {onlyarrayComment[0]?.includes("Family Medical History") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <div className="form-grou ms-4 d-md-flex justify-content-between">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="PTB"
                value="PTB"
                checked={
                  !getForm.family_medical_history?.includes("none") &&
                  getForm.family_medical_history?.includes("PTB")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalPTB"
              >
                PTB
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Asthma"
                value="Asthma"
                checked={
                  !getForm.family_medical_history?.includes("none") &&
                  getForm.family_medical_history?.includes("Asthma")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalAsthma"
              >
                Asthma
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="COPD"
                value="COPD"
                checked={
                  !getForm.family_medical_history?.includes("none") &&
                  getForm.family_medical_history?.includes("COPD")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalCOPD"
              >
                COPD
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Diabetes"
                value="Diabetes"
                checked={
                  !getForm.family_medical_history?.includes("none") &&
                  getForm.family_medical_history?.includes("Diabetes")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalDiabetes"
              >
                Diabetes
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Heart Disease"
                value="Heart Disease"
                checked={
                  !getForm.family_medical_history?.includes("none") &&
                  getForm.family_medical_history?.includes("Heart Disease")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalheartDisease"
              >
                Heart Disease
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="Others"
                value="Others"
                checked={
                  !getForm.family_medical_history?.includes("none") &&
                  getForm.family_medical_history?.includes("Others")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalOthers"
              >
                Others
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="none"
                value="none"
                required={
                  getForm.family_medical_history == null ||
                  getForm.family_medical_history?.length < 1
                    ? true
                    : false
                }
                checked={
                  getForm.family_medical_history == null ||
                  getForm.family_medical_history == [] ||
                  getForm.family_medical_history == "" ||
                  getForm.family_medical_history?.includes("none")
                }
                onChange={(event) => {
                  setshouldFormSubmit(true);
                  handleFamilyHistory(event);
                }}
              />
              <label
                className="form-check-label my-auto ms-3"
                htmlFor="familyMedicalnone"
              >
                None
              </label>
            </div>
          </div>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="medicine_history">
            Any medication in progress:
            {onlyarrayComment[0]?.includes("Any Medication") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="medicine_history"
            value={getForm.medicine_history}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, medicine_history: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <span className="border_bottom"></span>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="phone">
            Phone Number:
            {onlyarrayComment[0]?.includes("Phone") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            disabled
            // pattern="^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$"
            // type="tel"
            placeholder="enter mobile number"
            id="phone"
            value={getForm.phone}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, phone: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="patient_type">
            Patient Type:
            {onlyarrayComment[0]?.includes("Patient Type") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="patient_type"
            value={getForm.patient_type}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, patient_type: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option value="Suspect">Suspect</option>
            <option value="New">New</option>
            <option value="Relapse">Relapse</option>
            <option value="Previously treated">Previously treated</option>
            <option value="Follow Up">Follow Up</option>
            <option value="F2">F2</option>
            <option value="F3">F3</option>
            <option value="F4">F4</option>
            <option value="F5">F5</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="tb_type">
            Type of Diagnosis:
            {onlyarrayComment[0]?.includes("Diagnosis") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="tb_type"
            value={getForm.tb_type}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, tb_type: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option value="Pulmonary TB">Pulmonary TB</option>
            <option value="COPD">COPD</option>
            <option value="Asthma">Asthma</option>
            <option value="Healthy">Healthy</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="contact_with_infected">
            In contact with infected people:
            {onlyarrayComment[0]?.includes(
              "In Contact With Infected People"
            ) && <Report className="fs-4 my-auto text-warning" />}
          </label>
          <select
            className="form-control form-control-lg"
            id="contact_with_infected"
            value={getForm.contact_with_infected}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, contact_with_infected: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="dob">
            DOB:
            {onlyarrayComment[0]?.includes("DOB") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="date"
            placeholder="select date"
            id="dob"
            disabled
            value={getForm.dob || ""}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, dob: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="gender">
            Gender:
            {onlyarrayComment[0]?.includes("Gender") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="gender"
            value={getForm.gender}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, gender: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Male</option>
            <option>Female</option>
            <option>Others</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="age">
            Age:
            {onlyarrayComment[0]?.includes("Age") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="number"
            placeholder="select date"
            id="age"
            value={getForm.age}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, age: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="height">
            Height:
            {onlyarrayComment[0]?.includes("Height") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="number"
            placeholder="enter height"
            id="height"
            step="0.1"
            lang="nb"
            value={getForm.height}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, height: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="weight">
            Weight:
            {onlyarrayComment[0]?.includes("Weight") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="number"
            placeholder="enter weight"
            id="weight"
            min={0}
            step="0.1"
            value={getForm.weight}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, weight: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="education_level">
            Education Level:
            {onlyarrayComment[0]?.includes("Education Level") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="education_level"
            value={getForm.education_level}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, education_level: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Literate</option>
            <option>Not Literate</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="profession">
            Profession:
            {onlyarrayComment[0]?.includes("Profession") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="text"
            placeholder="enter profession"
            id="profession"
            value={getForm.profession}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, profession: e.target.value });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="marital_status">
            Marital Status:
            {onlyarrayComment[0]?.includes("Marital Status") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="marital_status"
            value={getForm.marital_status}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, marital_status: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Married</option>
            <option>Unmarried</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="occupants_in_one_rooms">
            Occupants in patient’s room:
            {onlyarrayComment[0]?.includes("Occupants In Patient's Room") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="number"
            placeholder="enter occupants per room"
            id="occupants_in_one_rooms"
            value={getForm.occupants_in_one_rooms}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({
                ...getForm,
                occupants_in_one_rooms: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="ventilated">
            Is house ventilated:
            {onlyarrayComment[0]?.includes("Is House Ventilated") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="ventilated"
            value={getForm.ventilated}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, ventilated: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-2">
          <label className="text-nowrap" htmlFor="feel_mucus">
            Mucus in throat:
            {onlyarrayComment[0]?.includes("Feel Mucus") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="feel_mucus"
            value={getForm.feel_mucus}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, feel_mucus: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-2">
          <label className="text-nowrap" htmlFor="volunteer_cough">
            Volunteer Cough:
            {onlyarrayComment[0]?.includes("Volunteer Cough") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="volunteer_cough"
            value={getForm.volunteer_cough}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, volunteer_cough: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-2">
          <label className="text-nowrap" htmlFor="test_name">
            Test Name:
            {onlyarrayComment[0]?.includes("Test Name") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="test_name"
            value={getForm.test_name}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, test_name: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Xpert</option>
            <option>Afb</option>
            <option>Xray</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="test_date">
            Test Conducted Date:
            {onlyarrayComment[0]?.includes("Test Date") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="date"
            placeholder="select date"
            id="test_date"
            value={getForm.test_date || ""}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, test_date: e.target.value });
            }}
          />
          <p className="small fw-bold">
            {moment(getForm.test_date).format("YYYY-MM-DD")}
          </p>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="test_result">
            Test result:
            {onlyarrayComment[0]?.includes("Test Result") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="test_result"
            value={getForm.test_result}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, test_result: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>positive</option>
            <option>negative</option>
            <option>Pos w.o.r</option>
            <option>Neg w.o.r</option>
            <option>Not confirm</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="xpert_infection">
            Xpert Infection:
            {onlyarrayComment[0]?.includes("Xpert Infection") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <select
            className="form-control form-control-lg"
            id="xpert_infection"
            value={getForm.xpert_infection}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, xpert_infection: e.target.value });
            }}
          >
            <option select="true" value="">
              Choose...
            </option>
            <option>Trace</option>
            <option>V.Low</option>
            <option>Low</option>
            <option>Medium</option>
            <option>High</option>
            <option>Scanty</option>
          </select>
        </div>
        <div className="form-group col-md-4 col-lg-3">
          <label className="text-nowrap" htmlFor="afb_grading">
            AFB Sample (high grading):
            {onlyarrayComment[0]?.includes("AFB Sample") && (
              <Report className="fs-4 my-auto text-warning" />
            )}
          </label>
          <input
            className="form-control form-control-lg"
            type="number"
            placeholder="enter grade"
            id="afb_grading"
            value={getForm.afb_grading}
            onChange={(e) => {
              setshouldFormSubmit(true);
              setgetForm({ ...getForm, afb_grading: e.target.value });
            }}
          />
        </div>
        <div className="row mt-5">
          <div className="col-md-6 mx-auto">
            {shouldFormSubmit && (
              <button
                onClick={(e) => {
                  setgetForm(ApiData);
                  setshouldFormSubmit(false);
                }}
                className="btn fs-4 col-md-4 btn-warning me-3"
              >
                Reset Form
              </button>
            )}
            {shouldFormSubmit ? (
              <button type="submit" className="btn fs-4 col-md-4 btn-success">
                {sendingReq ? <CircleRounded /> : "Update"}
              </button>
            ) : (
              <button className="btn fs-4 col-md-4 btn-primary">
                Back <Link to="/UsersTable" />
              </button>
            )}
          </div>
        </div>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        onClose={() => {
          setsuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "warning" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar>
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        onClose={() => {
          setsuccessMessage("");
          setOpen(false);
        }}
      >
        <Alert
          severity={successMessage ? "success" : "warning"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage ? successMessage : "Form Was not Submitted"}
        </Alert>
      </Snackbar> */}
    </div>
  );
}
