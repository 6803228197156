import React, { useState, useEffect } from "react";
import TableComponent from "../../Atoms/TableComponent";
import AdminData from "../../API/AdminData";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  padding: 20,
  borderRadius: "8px",
};

export default function UserTable(props) {
  const {
    successMessage,
    ErrorMessage,
    setsuccessMessage,
    setErrorMessage,
    facilityNames,
    roleNames,
  } = props;

  const [usersList, setusersList] = useState([]);
  const [sendingReq, setsendingReq] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [AddUserModal, setAddUserModal] = useState(false);
  const [username, setUsername] = useState("");
  const [role_id, setRole_id] = useState();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [facility_id, setfacility_id] = useState();
  const [forUpdate, setforUpdate] = useState(false);
  const [user_id, setuser_id] = useState();
  const [isActive, setisActive] = useState();
  const [deletedUser, setdeletedUser] = useState(false);

  useEffect(() => {
    setsendingReq(true);
    AdminData.AllUsersList().then((res) => {
      res = res.data;
      setsendingReq(false);
      for (let i in res) {
        res[i].sNo = Number(i) + 1;
      }
      setusersList(res);
    });
  }, [refreshTable]);

  const handleSubmit = async (e) => {
    setsendingReq(true);
    const payload = {
      phone: phone,
      password: password,
      name: username,
      role_id: role_id || 1,
      facility_id: facility_id || 1,
      is_active: isActive,
    };
    const res = forUpdate
      ? AdminData.UpdateUser(user_id, payload)
      : AdminData.AddNewUser(payload);
    res
      .then((value) => {
        if (value.error) {
          setsendingReq(false);
          setErrorMessage(`Error: ${value.error}`);
        } else {
          setsendingReq(false);
          setAllStatesNull();
          setAddUserModal(false);
          setsuccessMessage(value.data.detail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setrefreshTable(!refreshTable);
    e.preventDefault();
  };

  const setAllStatesNull = () => {
    setPhone("");
    setRole_id();
    setPassword("");
    setfacility_id();
    setUsername("");
    setuser_id("");
    setforUpdate(false);
  };

  const columnDefs = [
    {
      headerName: "S.No",
      field: "sNo",
      sortable: true,
    },
    {
      headerName: "Username",
      field: "user_name",
      sortable: true,
    },
    {
      headerName: "Role id/name",
      field: "role_id",
      cellRenderer: function (params) {
        return (
          <p>
            {params.data.role_name} ({params.data.role_id})
          </p>
        );
      },
      sortable: true,
    },
    {
      headerName: "Facility id/name",
      field: "facility_id",
      cellRenderer: function (params) {
        return (
          <p>
            {params.data.name} ({params.data.facility_id})
          </p>
        );
      },
      sortable: true,
    },
    {
      headerName: "User Id",
      field: "user_id",
      sortable: true,
    },
    {
      headerName: "Phone",
      field: "phone",
      sortable: true,
    },
    {
      headerName: "Status",
      field: "is_active",
      cellRenderer: (params) =>
        params.value ? (
          <p className="fs-14 Lato text-success">Active</p>
        ) : (
          <p className="fs-14 Lato text-danger">Inactive</p>
        ),
      sortable: true,
    },
    {
      headerName: "Action",
      field: "sNo",
      sortable: true,
      minWidth: 70,
      cellRenderer: function (params) {
        return (
          <Stack direction="row" spacing={2}>
            <IconButton>
              <Tooltip title="Delete User/Ward Boy">
                <Edit
                  onClick={() => {
                    params.data.is_active
                      ? setdeletedUser(false)
                      : setdeletedUser(true);
                    setforUpdate(true);
                    setuser_id(params.data.user_id);
                    setfacility_id(params.data.facility_id);
                    setRole_id(params.data.role_id);
                    setAddUserModal(true);
                  }}
                  className="mx-auto fs-1 text-primary"
                />
              </Tooltip>
            </IconButton>
            {params.data.is_active ? (
              <IconButton>
                <Tooltip title="Delete User/Ward Boy">
                  <Delete
                    onClick={() => {
                      const user_id = params.data.user_id;
                      if (user_id) {
                        const res = AdminData.DeleteUser(user_id);
                        res.then((value) => {
                          setsuccessMessage(
                            `User/Ward Boy ${user_id} has been removed successfully from your List`
                          );
                          setrefreshTable(!refreshTable);
                        });
                      }
                    }}
                    className="mx-auto fs-1 text-danger"
                  />
                </Tooltip>
              </IconButton>
            ) : null}
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <TableComponent
        value={"usersList"}
        Data={usersList}
        columnDefs={columnDefs}
        floatingFilter={true}
        buttonText="New User"
        setAddUserModal={setAddUserModal}
        sendingReq={sendingReq}
        isNotCounting={true}
      />
      <Modal
        open={AddUserModal}
        onClose={() => {
          setAllStatesNull();
          setAddUserModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-light col-12 col-md-7" style={style}>
          <div className="h-100 w-100 d-flex">
            <form
              className="mx-auto my-auto w-100 w-md-75"
              type="submit"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                Add New User
              </p>
              {/* {!forUpdate && (
                <div className="text-start mb-4">
                  <input
                    autoComplete="off"
                    className="mb-1"
                    value={username}
                    type="text"
                    name="name"
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Patient Name"
                  />
                </div>
              )} */}
              {!forUpdate && (
                <div className="text-start mb-4">
                  <input
                    autoComplete="off"
                    className="mb-1"
                    pattern="^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$"
                    type="tel"
                    value={phone}
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="enter phone number"
                  />
                </div>
              )}
              <div className="text-start mb-4">
                <input
                  className="mb-1"
                  autoComplete="off"
                  value={password}
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="enter password"
                />
              </div>
              {!forUpdate && (
                <div className="form-group mb-4">
                  <label className="text-nowrap" htmlFor="role_id">
                    Role:
                  </label>
                  <select
                    className="form-control form-control-lg"
                    id="role_id"
                    value={role_id}
                    onChange={(e) => {
                      setRole_id(e.target.value);
                    }}
                  >
                    {roleNames?.map((roles) => (
                      <option select="true" value={roles.id}>
                        {roles.role_name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {!forUpdate && (
                <div className="form-group mb-4">
                  <label className="text-nowrap" htmlFor="facility_id">
                    Facility:
                  </label>
                  <select
                    className="form-control form-control-lg"
                    id="facility_id"
                    value={facility_id}
                    onChange={(e) => {
                      setfacility_id(e.target.value);
                    }}
                    required
                  >
                    {facilityNames?.map((facility) => (
                      <option key={facility.id} value={facility.id}>
                        {facility.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {forUpdate && deletedUser && (
                <div className="form-group mb-4">
                  <label className="text-nowrap" htmlFor="is_Active">
                    Activate User:
                  </label>
                  <select
                    className="form-control form-control-lg"
                    id="is_Active"
                    value={isActive}
                    onChange={(e) => {
                      setisActive(e.target.value);
                    }}
                  >
                    <option value="">choose</option>
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </select>
                </div>
              )}
              <button
                type="submit"
                className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45"
              >
                {sendingReq ? (
                  <CircularProgress color="inherit" size={22} />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        onClose={() => {
          setsuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "error" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
