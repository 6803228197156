import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AdbIcon from "@mui/icons-material/Adb";
import logoImg from "../Assets/Images/aiforlifelogo.svg";
import { Link } from "react-router-dom";
import ProfileImage from "../Atoms/ProfileImage";

const adminPages = [
  { page: "Home", link: "/home" },
  { page: "TB Data", link: "/UsersTable" },
  { page: "Covid Data", link: "/covid-data" },
  { page: "Analysis", link: "/Analysis" },
  { page: "Validation", link: "/Validation" },
  { page: "Approval", link: "/Approval" },
  { page: "Check List", link: "/checkList" },
  { page: "Admin", link: "/userManagement" },
];

const docPages = [
  { page: "Analysis", link: "/Analysis" },
  { page: "Covid Data", link: "/covid-data" },
];

const wardBoypages = [
  { page: "TB Data", link: "/UsersTable" },
  { page: "Covid Data", link: "/covid-data" },
  { page: "Check List", link: "/checkList" },
];

const pages = [
  { page: "Home", link: "/home" },
  { page: "TB Data", link: "/UsersTable" },
  { page: "Covid Data", link: "/covid-data" },
  { page: "Analysis", link: "/Analysis" },
];

const analystPages = [
  { page: "Home", link: "/home" },
  { page: "TB Data", link: "/UsersTable" },
  { page: "Covid Data", link: "/covid-data" },
  { page: "Check List", link: "/checkList" },
  { page: "Analysis", link: "/Analysis" },
  { page: "Validation", link: "/Validation" },
  { page: "Approval", link: "/Approval" },
];

const NavPages =
  localStorage.getItem("role") === process.env.REACT_APP_ADMIN
    ? adminPages
    : localStorage.getItem("role") === process.env.REACT_APP_DOCTOR
    ? docPages
    : localStorage.getItem("role") === process.env.REACT_APP_ANALYST
    ? analystPages
    : localStorage.getItem("role") === process.env.REACT_APP_WARD_BOY
    ? wardBoypages
    : pages;

function Navbar() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  //   const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [openMobileMenu, setopenMobileMenu] = useState(false);
  const location = useLocation();

  const handleOpenMenu = () => {
    setopenMobileMenu(!openMobileMenu);
  };

  // const handleCloseMenu = () => {
  //   setAnchorEl(null);
  // };

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  return (
    <nav className="navbar navbar-expand-md navbar-light bg-lightBlue">
      <div className="container mx-auto">
        <img src={logoImg} style={{ width: "7%" }} />
        <IconButton className="d-flex d-md-none" onClick={handleOpenMenu}>
          <MenuIcon />
        </IconButton>
        <Box
          className={`navLinks navbar-nav w-100 justify-content-end ${
            openMobileMenu ? "d-flex" : "d-none d-md-flex"
          }`}
          sx={{
            flexGrow: 1,
          }}
        >
          {NavPages?.map((each) => (
            <Link
              to={each.link}
              className="navLinks"
              key={each.page}
              onClick={() => setopenMobileMenu(false)}
            >
              <MenuItem
                className="navLinks nav-item"
                selected={location.pathname === each.link}
              >
                <Typography textAlign="center" className="navLinks">
                  {each.page}
                </Typography>
              </MenuItem>
            </Link>
          ))}
          <MenuItem
            onClick={() => {
              localStorage.clear();
              window.location.reload();
            }}
            className="navLinks"
          >
            <Typography textAlign="center" className="navLinks">
              Logout
            </Typography>
          </MenuItem>
        </Box>
        {/* <ProfileImage name="Surbhi Dighe" /> */}

        {/* <IconButton sx={{ p: 0 }}>
          <Avatar alt={localStorage.getItem("role") || "?"} src="" />
        </IconButton> */}
      </div>
    </nav>
  );
}

export default Navbar;
