import React, { useState, useEffect } from "react";
import TableComponent from "../../Atoms/TableComponent";
import AdminData from "../../API/AdminData";
import {
  Alert,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Snackbar,
  Stack,
  Tooltip,
} from "@mui/material";
import { DomainDisabled, Edit } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  boxShadow: 24,
  padding: 20,
  borderRadius: "8px",
};

export default function RoleTable(props) {
  const { successMessage, ErrorMessage, setsuccessMessage, setErrorMessage } =
    props;

  const [facilityList, setFacilityList] = useState([]);
  const [sendingReq, setsendingReq] = useState(false);
  const [refreshTable, setrefreshTable] = useState(false);
  const [AddUserModal, setAddUserModal] = useState(false);
  const [role_id, setRole_id] = useState();
  const [forUpdate, setforUpdate] = useState(false);
  const [role_name, setrole_name] = useState();

  useEffect(() => {
    setsendingReq(true);
    AdminData.GetRolesDetail().then((res) => {
      res = res.data;
      setsendingReq(false);
      for (let i in res) {
        res[i].sNo = Number(i) + 1;
      }
      setFacilityList(res);
    });
  }, [refreshTable]);

  const handleSubmit = async (e) => {
    setsendingReq(true);
    const payload = {
      role_name: role_name,
    };
    const res = forUpdate
      ? AdminData.UpdateRole(role_id, payload)
      : AdminData.AddNewRole(payload);
    res
      .then((value) => {
        if (value.error) {
          setsendingReq(false);
          setErrorMessage(`Error: ${value.error}`);
        } else {
          setsendingReq(false);
          setAllStatesNull();
          setAddUserModal(false);
          setsuccessMessage(value.data.detail);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setrefreshTable(!refreshTable);
    e.preventDefault();
  };

  const setAllStatesNull = () => {
    setRole_id();
    setrole_name("");
    setforUpdate(false);
  };

  const columnDefs = [
    {
      headerName: "S.No",
      field: "sNo",
      sortable: true,
    },
    {
      headerName: "Role name",
      field: "role_name",
      sortable: true,
    },
    {
      headerName: "Role id",
      field: "id",
      sortable: true,
    },
    {
      headerName: "Action",
      field: "sNo",
      sortable: true,
      minWidth: 70,
      cellRenderer: function (params) {
        return (
          <Stack direction="row" spacing={2}>
            {params.data.is_active ? (
              <IconButton>
                <Tooltip title="Delete Facility">
                  <DomainDisabled
                    onClick={() => {
                      const role_id = params.data.id;
                      if (role_id) {
                        const res = AdminData.DeleteFacility(role_id);
                        res.then((value) => {
                          setsuccessMessage(
                            `Facility ${role_id} has been removed successfully from your List`
                          );
                          setrefreshTable(!refreshTable);
                        });
                      }
                    }}
                    className="mx-auto fs-1 text-danger"
                  />
                </Tooltip>
              </IconButton>
            ) : null}
            <IconButton>
              <Tooltip title="Delete Facility">
                <Edit
                  onClick={() => {
                    setforUpdate(true);
                    setRole_id(params.data.id);
                    setrole_name(params.data.role_name);
                    setAddUserModal(true);
                  }}
                  className="mx-auto fs-1 text-primary"
                />
              </Tooltip>
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <div>
      <TableComponent
        value={"facilityList"}
        Data={facilityList}
        columnDefs={columnDefs}
        floatingFilter={true}
        // buttonText="New Role"
        // setAddUserModal={setAddUserModal}
        sendingReq={sendingReq}
        isNotCounting={true}
      />
      <Modal
        open={AddUserModal}
        onClose={() => {
          setAllStatesNull();
          setAddUserModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="bg-light col-12 col-md-7" style={style}>
          <div className="h-100 w-100 d-flex">
            <form
              className="mx-auto my-auto w-100 w-md-75"
              type="submit"
              onSubmit={handleSubmit}
              autoComplete="off"
            >
              <p className="Noto_Sans fs-18 text-start fw-bold text-grey-black">
                {forUpdate ? "Update Role" : "  Add New Role"}
              </p>
              <div className="text-start mb-4">
                <input
                  className="mb-1"
                  value={role_name}
                  type="text"
                  name="role_name"
                  onChange={(e) => setrole_name(e.target.value)}
                  placeholder="Role Name"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary Noto_Sans fs-18 col-md-4 p-3 text-light fw-800 w-100 h-45"
              >
                {sendingReq ? (
                  <CircularProgress color="inherit" size={22} />
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        onClose={() => {
          setsuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "error" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
