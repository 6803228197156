import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, Navigate } from "react-router-dom";
import  Login from './Components/Routs/Login'
import  MainComponent from './Components/MainComponent'
import  ProtectedRoutes from './Components/Middleware/ProtectedRoutes/ProtectedRoutes'

function App() {
  return (
    <Routes>
    {localStorage.getItem("userIsLoggedIn") ? null : (
      <Route path="/login" element={<Login />} />
    )}
    <Route element={<ProtectedRoutes />}>
      <Route path="/*" element={<MainComponent />} />
    <Route path="/" element={<Navigate to="/home" replace />} />
    </Route>
  </Routes>
  );
}

export default App;
