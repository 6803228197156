import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Fade } from "react-awesome-reveal";

export default function YesNoChart(props) {
  const {
    cardHeight,
    id,
    chartHeight,
    heading,
    y_title,
    x_title,
    ApiData,
    colColors,
    facilityId,
    startDate,
    endDate,
    stackedChart,
  } = props;

  const [xCategories, setXCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const categoryMapping = {
      Yes: ["Yes", "Literate"],
      No: ["No", "Not Literate"],
      "Not Specified": [null],
    };

    if (ApiData) {
      // Transform API data into the desired series format
      const transformedSeries = Object.entries(categoryMapping)?.map(
        ([name, categories]) => {
          const data = [];
          for (const key in ApiData) {
            const count = categories?.reduce((total, category) => {
              const item = ApiData[key].find(
                (item) => item.category === category
              );
              return total + (item ? item.count : 0);
            }, 0);
            data.push(count);
          }
          return {
            name: name,
            data: data,
          };
        }
      );

      const touppercase = Object.keys(ApiData);

      setXCategories(
        touppercase?.map((item) => {
          switch (item) {
            case "ventilated":
              return "Ventilated";
            case "education_level":
              return "Education level";
            case "medicine_history":
              return "Any medication";
            case "shortness_of_breath":
              return "Shortness of breath";
            case "fever":
              return "Fever";
            case "nights_sweats":
              return "Night sweats";
            case "weight_loss":
              return "Weight loss";
            case "loss_appetite":
              return "Appetite loss";
            case "wheezing":
              return "Wheezing sound";
            case "coughing_blood":
              return "Blood in cough";
            default:
              return "";
          }
        })
      );
      setSeries(transformedSeries);
    }
  }, [ApiData]);

  const options = {
    chart: {
      height: 350,
      type: "bar",
      toolbar: { show: false },
      stacked: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: colColors,
    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: "top",
      offsetY: -30,
      horizontalAlign: "right",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      markers: {
        radius: 10,
        offsetY: 2,
      },
    },
    tooltip: {
      enabled: true,
    },
    title: {
      text: heading,
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: "700",
        fontFamily: "poppins",
        color: "#000000",
      },
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: "#FFFFFF",
      width: 3,
      dashArray: 0,
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "10px",
        fontFamily: "Poppins",
        colors: ["black"],
      },
    },
    xaxis: {
      categories: xCategories,
      labels: {
        show: true,
      },
      title: {
        text: x_title,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
        tickAmount: 7,
      },
      title: {
        text: y_title,
      },
    },
  };

  return (
    <div className="graph-cards" key={id} style={{ height: cardHeight }}>
      <div id="chart">
        <Fade delay={1e2}>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={chartHeight}
          />
        </Fade>
      </div>
    </div>
  );
}
