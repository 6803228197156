import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { CircularProgress, Skeleton, Snackbar } from "@mui/material";
import moment from "moment";
import React from "react";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";

export default function CompareCard(props) {
  const {
    profileInfo,
    sendingReq,
    tableName,
    compareState,
    selectedValues,
    setSelectedValues,
    selectableoption,
    IsCheckBoxVisible,
  } = props;
  const [open, setOpen] = useState(false);
  const [copiedText, setCopiedText] = useState("");

  const compareString = (comparable, text) => {
    return (
      comparable?.toString().toLowerCase() !== text?.toString().toLowerCase()
    );
  };

  const ProfileRow = ({ label, text, copyText, comparable, array }) => (
    <div
      data-toggle="tooltip"
      data-placement="top"
      title={
        array
          ? comparable === null || []
            ? "was null field"
            : comparable
          : comparable || "was null field"
      }
      className={`row pe-3 mb-1 ${
        tableName === "Updated" && compareString(comparable, text)
          ? "bg-highlighted"
          : "null"
      }`}
    >
      <div className="col-5 fs-16 fw-700">
        {label}
        {profileInfo.length < 0 ? ":" : null}
      </div>
      {copyText ? (
        <CopyToClipboard
          comparable={comparable}
          text={text}
          onCopy={handleCopy}
        >
          <span className="text-end col-7 fs-14 fw-500">{text}</span>
        </CopyToClipboard>
      ) : (
        <div className="text-end col-7 fs-14 fw-500">
          {array ? text?.map((each) => `${each}`)?.join(", ") : text}{" "}
          {IsCheckBoxVisible && (
            <span
              className="text-primry ms-2 cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                selectedValues.includes(label)
                  ? setSelectedValues(
                      selectedValues.filter((item) => item !== label)
                    )
                  : setSelectedValues([...selectedValues, label]);
              }}
            >
              {selectedValues.includes(label) ? (
                <CheckBox className="text-dark" />
              ) : (
                <CheckBoxOutlineBlank className="text-dark" />
              )}
            </span>
          )}
        </div>
      )}
    </div>
  );

  const handleCopy = (text) => {
    setOpen(true);
    setCopiedText(text);
  };

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  return sendingReq ? (
    <div className="text-center d-flex h-100 flex-column w-100 text-center">
      <div className="my-auto">
        <CircularProgress />
        <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
          Loading...
        </p>
      </div>
    </div>
  ) : (
    <div className="ticket-cards-2 mb-4">
      <p className="fs-20 fw-700 text-blue poppins-Med">{tableName}</p>
      <ProfileRow
        label="Patient Id"
        text={profileInfo.p_id}
        comparable={compareState?.p_id}
        copyText={true}
      />
      <ProfileRow
        label="Serial No"
        text={profileInfo.serial_number}
        comparable={compareState?.serial_number}
      />
      <ProfileRow
        label="Visit Id"
        text={profileInfo.visit_id}
        comparable={compareState?.visit_id}
      />
      <ProfileRow
        label="Name"
        text={profileInfo.name}
        comparable={compareState?.name}
      />
      {/* <ProfileRow
        label="Created At"
        text={
          profileInfo.created_at &&
          moment(profileInfo.created_at).format("DD/MM/YYYY")
        }
      /> */}
      <ProfileRow
        label="Diagnosis-Date"
        text={profileInfo.initial_diagnose}
        comparable={moment(compareState?.initial_diagnose).format("YYYY-MM-DD")}
      />
      <ProfileRow
        label="cough"
        text={profileInfo.cough}
        comparable={compareState?.cough}
      />
      <ProfileRow
        label="Coughing For"
        text={profileInfo.cough_frq}
        comparable={compareState?.cough_frq}
      />
      <ProfileRow
        label="Blood In Cough"
        text={profileInfo.coughing_blood}
        comparable={compareState?.coughing_blood}
      />
      <ProfileRow
        label="Fever"
        text={profileInfo.fever}
        comparable={compareState?.fever}
      />
      <ProfileRow
        label="Fever For"
        text={profileInfo.fever_for}
        comparable={compareState?.fever_for}
      />
      <ProfileRow
        label="Fever Level"
        text={profileInfo.fever_level}
        comparable={compareState?.fever_level}
      />
      <ProfileRow
        label="Fever Time"
        text={profileInfo.fever_time}
        comparable={compareState?.fever_time}
      />
      <ProfileRow
        label="Night Sweats"
        text={profileInfo.nights_sweats}
        comparable={compareState?.nights_sweats}
      />
      <ProfileRow
        label="Wheezing Sound"
        text={profileInfo.wheezing}
        comparable={compareState?.wheezing}
      />
      <ProfileRow
        label="Shortness Of Breath"
        text={profileInfo.shortness_of_breath}
        comparable={compareState?.shortness_of_breath}
      />
      <ProfileRow
        label="Shortness Of Breath For"
        text={profileInfo.shortness_of_breath_for}
        comparable={compareState?.shortness_of_breath_for}
      />
      <ProfileRow
        label="Feel Mucus"
        text={profileInfo.feel_mucus}
        comparable={compareState?.feel_mucus}
      />
      <ProfileRow
        label="Weight-Loss"
        text={profileInfo.weight_loss}
        comparable={compareState?.weight_loss}
      />
      <ProfileRow
        label="Loss Of Appetite"
        text={profileInfo.loss_appetite}
        comparable={compareState?.loss_appetite}
      />
      <ProfileRow
        label="Smoking History"
        text={profileInfo.smoking_history}
        comparable={compareState?.smoking_history}
      />
      <ProfileRow
        label="Medical Conditions"
        array={profileInfo.medical_conditions && true}
        text={profileInfo.medical_conditions}
        comparable={compareState?.medical_conditions}
      />
      <ProfileRow
        label="Family Medical History"
        array={profileInfo.family_medical_history && true}
        text={profileInfo.family_medical_history}
        comparable={compareState?.family_medical_history}
      />
      <ProfileRow
        label="Any Medication"
        text={profileInfo.medicine_history}
        comparable={compareState?.medicine_history}
      />
      <ProfileRow
        label="Phone"
        text={profileInfo.phone}
        comparable={compareState?.phone}
      />
      <ProfileRow
        label="Patient Type"
        text={profileInfo.patient_type}
        comparable={compareState?.patient_type}
      />
      <ProfileRow
        label="Diagnosis"
        text={profileInfo.tb_type}
        comparable={compareState?.tb_type}
      />
      <ProfileRow
        label="In Contact With Infected People"
        text={profileInfo.contact_with_infected}
        comparable={compareState?.contact_with_infected}
      />
      <ProfileRow
        label="DOB/Age"
        text={profileInfo.age}
        comparable={compareState?.age}
      />
      <ProfileRow
        label="Gender"
        text={profileInfo.gender}
        comparable={compareState?.gender}
      />
      <ProfileRow
        label="Height"
        text={profileInfo.height}
        comparable={compareState?.height}
      />
      <ProfileRow
        label="Weight"
        text={profileInfo.weight}
        comparable={compareState?.weight}
      />
      <ProfileRow
        label="Education Level"
        text={profileInfo.education_level}
        comparable={compareState?.education_level}
      />
      <ProfileRow
        label="Profession"
        text={profileInfo.profession}
        comparable={compareState?.profession}
      />
      <ProfileRow
        label="Marital Status"
        text={profileInfo.marital_status}
        comparable={compareState?.marital_status}
      />
      <ProfileRow
        label="Occupants In Patient's Room"
        text={profileInfo.occupants_in_one_rooms}
        comparable={compareState?.occupants_in_one_rooms}
      />
      <ProfileRow
        label="Is House Ventilated"
        text={profileInfo.ventilated}
        comparable={compareState?.ventilated}
      />
      <ProfileRow
        label="Volunteer Cough"
        text={profileInfo.volunteer_cough}
        comparable={compareState?.volunteer_cough}
      />
      <ProfileRow
        label="Test Name"
        text={profileInfo.test_name}
        comparable={compareState?.test_name}
      />
      <ProfileRow
        label="Test Result"
        text={profileInfo.test_result}
        comparable={compareState?.test_result}
      />
      <ProfileRow
        label="Xpert Infection"
        text={profileInfo.xpert_infection}
        comparable={compareState?.xpert_infection}
      />
      <ProfileRow
        label="AFB Sample"
        text={profileInfo.afb_grading}
        comparable={compareState?.afb_grading}
      />
      <ProfileRow
        label="Test Date"
        text={profileInfo.test_date}
        comparable={moment(compareState?.test_date).format("YYYY-MM-DD")}
      />
      {/* <ProfileRow
        label="Last Cough Date"
        text={
          profileInfo.last_cough &&
          moment(profileInfo.last_cough).format("DD/MM/YYYY")
        }
      /> */}
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={`Copied:  ${copiedText}`}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        severity="success"
      />
      {/* <ProfileRow
        label="no_of_rooms"
        text={profileInfo.no_of_rooms}
        comparable={compareState?.no_of_rooms}
      /> */}
      {/* <ProfileRow
        label="no_of_residents"
        text={profileInfo.no_of_residents}
        comparable={compareState?.no_of_residents}
      /> */}
    </div>
  );
}
