import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Snackbar } from "@mui/base";
import { Alert } from "@mui/material";
import UserTable from "./UserTable";
import AdminData from "../../API/AdminData";
import FacilityTable from "./FacilityTable";
import RoleTable from "./RoleTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Index(props) {
  const [successMessage, setsuccessMessage] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [value, setValue] = useState(0);
  const [roleNames, setroleNames] = useState([]);
  const [facilityNames, setfacilityNames] = useState([]);

  useEffect(() => {
    AdminData.GetRolesDetail().then((res) => {
      res = res.data;
      setroleNames(res);
    });
  }, [value]);

  useEffect(() => {
    AdminData.GetFacilityDetail().then((res) => {
      res = res.data;
      setfacilityNames(res);
    });
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="secondary-bg" id="top">
      <Box className="container px-0 px-md-4" sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          className="mx-auto"
          sx={{ fontSize: "14px", fontWeight: 700, width: 600 }}
        >
          <Tab
            className="tab-buttons fw-900 fs-14 Noto_Sans text-center"
            label="User Table"
          />
          <Tab
            className="tab-buttons fw-900 fs-14 Noto_Sans text-center"
            label="Facility Table"
          />
          <Tab
            className="tab-buttons fw-900 fs-14 Noto_Sans text-center"
            label="Role Table"
          />
        </Tabs>
        <Box>
          <TabPanel value={value} index={0}>
            <UserTable
              successMessage={successMessage}
              ErrorMessage={ErrorMessage}
              setsuccessMessage={setsuccessMessage}
              setErrorMessage={setErrorMessage}
              facilityNames={facilityNames}
              roleNames={roleNames}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <FacilityTable
              successMessage={successMessage}
              ErrorMessage={ErrorMessage}
              setsuccessMessage={setsuccessMessage}
              setErrorMessage={setErrorMessage}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <RoleTable
              successMessage={successMessage}
              ErrorMessage={ErrorMessage}
              setsuccessMessage={setsuccessMessage}
              setErrorMessage={setErrorMessage}
            />
          </TabPanel>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : ErrorMessage ? true : false}
        onClose={() => {
          setsuccessMessage("");
          setErrorMessage("");
        }}
      >
        <Alert
          severity={ErrorMessage ? "error" : successMessage && "success"}
          autohideduration={3000}
          sx={{ width: 500, fontSize: "18px" }}
        >
          {successMessage || ErrorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
