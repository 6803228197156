const topStatsData = [
  {
    id: 1,
    heading: "Total Participants",
    x_title: "Facility",
    y_title: "Number of Participants",
    cardHeight: 308,
    chartHeight: 290,
    colColors: ["#49BEFF", "#FFAE1F", "#5D87FF", "#13DEB9"],
    series: [
      {
        name: "Asthma",
        data: [44, 55, 57],
      },
      {
        name: "TB",
        data: [76, 85, 101],
      },
      {
        name: "COPD",
        data: [35, 41, 56],
      },
      {
        name: "Healthy",
        data: [55, 21, 76],
      },
    ],
    xCategories: ["GD", "Mayo", "Independent"],
  },
  {
    id: 2,
    heading: "Total Samples",
    x_title: "Facility",
    y_title: "Number of Samples",
    cardHeight: 308,
    chartHeight: 290,
    colColors: ["#49BEFF", "#FFAE1F", "#5D87FF", "#13DEB9"],
    series: [
      {
        name: "Asthma",
        data: [44, 55, 57],
      },
      {
        name: "TB",
        data: [76, 85, 101],
      },
      {
        name: "COPD",
        data: [35, 41, 56],
      },
      {
        name: "Healthy",
        data: [55, 21, 76],
      },
    ],
    xCategories: ["GD", "Mayo", "Independent"],
  },
];

export default topStatsData;
