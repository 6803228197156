import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Fade } from "react-awesome-reveal";
import HomePageApi from "../API/HomePageApi";
import { Skeleton } from "@mui/material";
import { he } from "date-fns/locale";

export default function FeaturesBarChart(props) {
  const {
    cardHeight,
    id,
    chartHeight,
    heading,
    y_title,
    x_title,
    facilityId,
    startDate,
    endDate,
    singleFeature,
    onlyFacility,
    setsingleFeature,
    medicalStatus,
  } = props;

  const [apiData, setapiData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [colColors, setColColors] = useState(["#49BEFF", "#13DEB9", "#FFAE1F"]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await HomePageApi.GetPieFeaturesStats(
        facilityId,
        startDate,
        endDate,
        medicalStatus,
        singleFeature
      );
      const apiData = response.data;
      setapiData(apiData);
    };
    fetchData();
  }, [facilityId, startDate, endDate, medicalStatus, singleFeature]);

  useEffect(() => {
    const series = [
      {
        name:
          heading === "null" || heading === null ? "Not Specified" : heading,
        data: apiData?.map((item) => {
          if (item.tooltip) {
            item.tooltip = item.tooltip.replace("Mayo", "Facility");
          }
          return item.count;
        }),
      },
    ];
    setSeriesData(series);
  }, [apiData, heading]);

  const getLabelName = (label) => {
    switch (label) {
      case "More than a week":
        return "> Week";
      case "Less than a week":
        return "< Week";
      case "More than a month":
        return "> Month";
      default:
        return label || "Not Specified";
    }
  };
  const state = {
    series: seriesData,
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: { show: false },
        stacked: true,
      },
      plotOptions: {
        bar: {
          borderRadius: 3,
          dataLabels: {
            position: "top",
          },
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "top",
        offsetY: -30,
        horizontalAlign: "right",
        fontSize: "12px",
        fontFamily: "Poppins",
        fontWeight: 400,
        markers: {
          radius: 10,
          offsetY: 2,
        },
      },
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return (
            '<div class="custom-tooltip">' +
            "<span>Facility: " +
            series[seriesIndex][dataPointIndex] +
            "</span>" +
            "</div>"
          );
        },
      },
      title: {
        text: onlyFacility ? "" : heading,
        align: "left",
        style: {
          fontSize: "24px",
          fontWeight: "700",
          fontFamily: "poppins",
          color: "#000000",
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: "#FFFFFF",
        width: 3,
        dashArray: 0,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "10px",
          fontFamily: "Poppins",
          colors: ["black"],
        },
      },
      colors: colColors,
      xaxis: {
        categories: apiData?.map((item) => getLabelName(item.label)),
        labels: {
          show: true,
          rotate: 0,
          style: {
            fontSize: "7px",
            fontWeight: 500,
            overFlow: "scroll",
          },
        },
        title: {
          text:
            singleFeature == "shortness_of_breath_for"
              ? "Shortness of Breath for"
              : singleFeature == "cough_frq"
              ? "Coughing for"
              : singleFeature == "patient_type"
              ? "Patient Type"
              : null,
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
          tickAmount: 7,
        },
        title: {
          text: y_title,
        },
      },
    },
  };

  return (
    <div className="position-relative" key={id} style={{ height: cardHeight }}>
      {onlyFacility ? (
        <p
          className="poppins-Med position-absolute"
          style={{
            paddingLeft: "5px",
            fontSize: "24px",
            fontWeight: "700",
            fontFamily: "poppins",
            color: "#000000",
          }}
        >
          {singleFeature == "shortness_of_breath_for"
            ? "Shortness of Breath"
            : singleFeature == "cough_frq"
            ? "Coughing"
            : null}
        </p>
      ) : null}
      {onlyFacility && (
        <div className="form-group col-6 mb-3 ms-auto text-end">
          <select
            className="form-control form-control-lg"
            id="feature"
            onChange={(e) => {
              setsingleFeature(e.target.value);
            }}
          >
            <option value="cough_frq">Coughing</option>
            <option value="shortness_of_breath_for">Shortness of Breath</option>
            {/* <option value="patient_type">Patient Type</option> */}
          </select>
        </div>
      )}
      <div id="chart">
        <Fade delay={1e1}>
          <ReactApexChart
            options={state.options}
            series={state.series}
            categories={state.xaxis}
            type="bar"
            height={chartHeight}
          />
        </Fade>
      </div>
    </div>
  );
}
