const YesNoStatsData = [
  {
    id: 1,
    heading: "Stats on the Response to Survey Questionare",
    x_title: "Features",
    y_title: "Number of Participants",
    cardHeight: 567,
    chartHeight: 547,
    colColors: ["#2DCDD4", "#045C9B", "#FFAE1F"],
    series: [
      {
        name: "Yes",
        data: [44, 55, 32, 57, 21, 83, 36, 78, 22, 64],
      },
      {
        name: "No",
        data: [76, 101, 46, 68, 89, 21, 43, 46, 67, 22],
      },
    ],
    xCategories: [
      "Vantilated",
      "Education level",
      "Any medication",
      "Shortness of breath",
      "Fever",
      "Night sweats",
      "Weight loss",
      "Appetite loss",
      "Wheezing sound",
      "Blood in Cough",
    ],
  },
];

export default YesNoStatsData;
