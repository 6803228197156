const PieChartsData = [
  {
    id: 1,
    heading: "Smoking History:",
    feature: "smoking_history",
    x_title: "",
    y_title: "",
    cardHeight: 300,
    chartHeight: 230,
    colColors: ["#EE7E2D", "#2DCDD4", "#FFC000", "#5B9BD5", "#c0c0c0"],
    series: [44, 55, 32, 57, 21],
    xCategories: [
      "Not Specified",
      "Smoker",
      "Non-Smoker",
      "Ex-Smoker",
      "Passive Smoker",
    ],
  },
  {
    id: 2,
    heading: "Gender:",
    feature: "gender",
    x_title: "",
    y_title: "",
    cardHeight: 300,
    chartHeight: 230,
    colColors: ["#FFC000", "#2DCDD4", "#c0c0c0"],
    series: [44, 55, 32],
    xCategories: ["Not Specified", "Male", "Female"],
  },
  {
    id: 3,
    heading: "Fever Time:",
    feature: "fever_time",
    x_title: "",
    y_title: "",
    cardHeight: 300,
    chartHeight: 230,
    colColors: ["#2DCDD4", "#5B9BD5", "#FFC000", "#c0c0c0"],
    series: [44, 55],
    xCategories: ["Not Specified", "Both", "Evening", "Morning"],
  },
  {
    id: 4,
    heading: "Fever level:",
    feature: "fever_level",
    x_title: "",
    y_title: "",
    cardHeight: 300,
    chartHeight: 230,
    colColors: ["#FF2D2D", "#FFC000", "#c0c0c0"],
    series: [44, 55],
    xCategories: ["Not Specified", "High", "Low"],
  },
  {
    id: 5,
    heading: "Test Conducted:",
    feature: "test_name",
    x_title: "",
    y_title: "",
    cardHeight: 300,
    chartHeight: 230,
    colColors: ["#EE7E2D", "#5B9BD5", "#2DCDD4", "#c0c0c0"],
    series: [44, 55],
    xCategories: ["Not Specified", "Xpert", "Afb"],
  },
  {
    id: 3,
    heading: "Marital Status:",
    feature: "marital_status",
    x_title: "",
    y_title: "",
    cardHeight: 300,
    chartHeight: 230,
    colColors: ["#5D87FF", "#EE7E2D", "#c0c0c0"],
    series: [44, 55],
    xCategories: ["Not Specified", "Married", "Unmarried"],
  },
];

export default PieChartsData;
