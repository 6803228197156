const columnFeaturesData = [
  // {
  //   id: 1,
  //   heading: "Independent:",
  //   maskHeading: "Facility 1:",
  //   facility: 1,
  //   x_title: "Features",
  //   y_title: "Count of Participants",
  //   cardHeight: 280,
  //   chartHeight: 280,
  //   colColors: ["#EE7E2D", "#2DCDD4", "#FFC000", "#5B9BD5", "#c0c0c0"],
  // },
  {
    id: 1,
    heading: "Mayo",
    maskHeading: "Facility 2:",
    facility: 2,
    x_title: "Features",
    y_title: "Count of Participants",
    cardHeight: 280,
    chartHeight: 280,
    colColors: ["#EE7E2D", "#2DCDD4", "#FFC000", "#5B9BD5", "#c0c0c0"],
  },
  {
    id: 2,
    heading: "Gulab Devi",
    maskHeading: "Facility 3:",
    facility: 3,
    x_title: "Features",
    y_title: "Count of Participants",
    cardHeight: 280,
    chartHeight: 280,
    colColors: ["#EE7E2D", "#2DCDD4", "#FFC000", "#5B9BD5", "#c0c0c0"],
  },
];

export default columnFeaturesData;
