import React, { useEffect, useState } from "react";
import Papa from "papaparse";

export const fetchCsvData = async () => {
  try {
    const response = await fetch("/data.csv"); // Replace with your CSV URL
    const text = await response.text();

    return new Promise((resolve, reject) => {
      Papa.parse(text, {
        complete: (result) => {
          resolve(result.data); // Return the parsed CSV data
        },
        header: true, // Use the first row as headers for JSON keys
        error: (error) => {
          reject(error);
        },
      });
    });
  } catch (error) {
    console.error("Error fetching or parsing CSV:", error);
    throw error;
  }
};
