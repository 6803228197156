import React, { useState, useEffect } from "react";
import { fetchCsvData } from "../../API/AudioData";
import TableComponent from "../../Atoms/TableComponent";
import { CircularProgress } from "@mui/material";
import moment from "moment";

export default function CovidData() {
  const [covidData, setCovidData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [playingAudio, setPlayingAudio] = useState(null);

  useEffect(() => {
    loadData();
    // Cleanup audio on component unmount
    return () => {
      if (playingAudio) {
        playingAudio.pause();
        playingAudio.currentTime = 0;
      }
    };
  }, []);

  const loadData = async () => {
    try {
      const data = await fetchCsvData();
      // Limit to first 895 records and add index
      const limitedData = data.slice(0, 825).map((item, index) => ({
        ...item,
        sNo: index + 1,
      }));
      setCovidData(limitedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error loading COVID data:", error);
      setIsLoading(false);
    }
  };

  // Add defaultColDef configuration
  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
    sortable: true,
  };

  // Update gridOptions
  const gridOptions = {
    suppressColumnVirtualisation: false,
    domLayout: "autoHeight",
    suppressHorizontalScroll: false,
    enableCellTextSelection: true,
    alwaysShowHorizontalScroll: false,
    alwaysShowVerticalScroll: false,
    suppressScrollOnNewData: true,
    onFirstDataRendered: (params) => {
      params.api.sizeColumnsToFit();
    },
    onGridSizeChanged: (params) => {
      params.api.sizeColumnsToFit();
    },
    getRowHeight: () => 80,
  };

  const columnDefs = [
    {
      headerName: "S.No",
      field: "sNo",
      sortable: true,
      maxWidth: 80,
      flex: 0.3,
    },
    {
      headerName: "Date",
      field: "date",
      filter: "agDateColumnFilter",
      flex: 0.8,
    },
    {
      headerName: "Corona Test",
      field: "corona_test",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      flex: 0.8,
    },
    {
      headerName: "Age",
      field: "age",
      filter: "agNumberColumnFilter",
      maxWidth: 100,
      flex: 0.5,
    },
    {
      headerName: "Gender",
      field: "gender",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      maxWidth: 110,
      flex: 0.5,
    },
    {
      headerName: "Medical History",
      field: "medical_history",
      filter: "agTextColumnFilter",
      enableRowGroup: true,
      flex: 1.2,
    },
    {
      headerName: "Smoker",
      field: "smoker",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      maxWidth: 110,
      flex: 0.5,
    },
    {
      headerName: "Reported Symptoms",
      field: "patient_reported_symptoms",
      filter: "agTextColumnFilter",
      flex: 1.5,
    },
    {
      headerName: "Audio Player",
      field: "cough_filename",
      filter: false,
      sortable: false,
      flex: 3,
      minWidth: 250,
      cellRenderer: (params) => {
        if (!params.value) return "No audio available";

        return (
          <div className="d-flex align-items-center justify-content-center w-100 p-2">
            <audio
              controls
              style={{ height: "40px", width: "100%" }}
              onClick={(e) => e.stopPropagation()}
            >
              <source
                src={`${process.env.REACT_APP_RECORDING_BASE_URL}/${params.value}`}
                type="audio/wav"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
        );
      },
    },
  ];

  const handleDownloadData = () => {
    // Create link element
    const link = document.createElement("a");
    link.href = process.env.REACT_APP_COUGH_DOWNLOAD;
    link.download = `SARS-CoV-2_cough_data.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className="row container mx-auto pt-3">
      <h1 className="mt-3 mb-3 text-blue">SARS-CoV-2 Data</h1>
      <button
        className="btn btn-primary mb-3"
        onClick={handleDownloadData}
        style={{
          width: "200px",
          fontSize: "1.2rem",
          padding: "15px 7px",
        }}
      >
        Download Data
      </button>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: "calc(100vh - 150px)",
          overflow: "hidden",
        }}
      >
        <TableComponent
          value="covidData"
          Data={covidData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          floatingFilter={true}
          sendingReq={isLoading}
          details={false}
          pagination={true}
          paginationPageSize={10}
          rowHeight={80}
          style={{ height: "100%" }}
          isNotCounting={true}
        />
      </div>
    </div>
  );
}
