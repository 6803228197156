import * as React from "react";
import { useState, useRef, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tables from "./Tables";
import moment from "moment";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Index(props) {
  const { setpatientId } = props;
  const [value, setValue] = useState(0);
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(
    moment(endDate).clone().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [medicalStatus, setmedicalStatus] = useState("Total");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="secondary-bg" id="top">
      <Box className="container" sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab className="tab-buttons" label="All" />
          <Tab className="tab-buttons" label="Mayo" />
          <Tab className="tab-buttons" label="Gulab Devi" />
        </Tabs>
        <Box>
          <TabPanel value={value} index={0}>
            <Tables
              setpatientId={setpatientId}
              facilityName={null}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              medicalStatus={medicalStatus}
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Tables
              setpatientId={setpatientId}
              facilityName="mayo"
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              medicalStatus={medicalStatus}
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Tables
              setpatientId={setpatientId}
              facilityName="GD"
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              medicalStatus={medicalStatus}
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel>
        </Box>
      </Box>
    </div>
  );
}
