import { Skeleton } from "@mui/material";
import React, { useEffect } from "react";

export default function DispImgesCard(props) {
  const { panImages, setpanImages, patientId } = props;
  useEffect(() => {
    setpanImages(null);
  }, [patientId]);
  return (
    <div className="graph-cards h-900">
      <div className="w-100 h-445 mb-2">
        {panImages ? (
          panImages?.map((image, index) => (
            <img
              className="mt-3 mb-2 w-100 mx-auto pans-output"
              key={index}
              src={`data:image/png;base64,${image.base64_string}`}
              alt={`Image ${index}`}
            />
          ))
        ) : (
          <>
            <h3 className="text-secondary">Select a recording</h3>
            <Skeleton width="100%" height="70vh" />
          </>
        )}
      </div>
    </div>
  );
}
