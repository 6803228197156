import { Snackbar } from "@mui/material";
import axios from "axios";

class AdminData {
  constructor() {
    this.state = {
      open: false,
    };
  }

  AllUsersList = async () => {
    try {
      const resp = await axios.get(`/admin/users`);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  GetFacilityDetail = async () => {
    try {
      const resp = await axios.get(`/admin/facilities`);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  GetRolesDetail = async () => {
    try {
      const resp = await axios.get(`/admin/roles`);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  DeleteUser = async (user_id) => {
    try {
      const resp = await axios.delete(`/admin/users/${user_id}`);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail.msg,
          };
      } else return { error: "Something went wrong" };
    }
  };

  AddNewUser = async (payload) => {
    try {
      const resp = await axios.post(`/admin/users`, payload);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  UpdateUser = async (user_id, payload) => {
    try {
      const resp = await axios.patch(`/admin/users/${user_id}`, payload);
      return resp;
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  AddNewFacility = async (payload) => {
    try {
      const resp = await axios.post(`/admin/facilities`, payload);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  UpdateFacility = async (facility_id, payload) => {
    try {
      const resp = await axios.patch(
        `/admin/facilities/${facility_id}`,
        payload
      );
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  DeleteFacility = async (facility_id) => {
    try {
      const resp = await axios.delete(`/admin/facilities/${facility_id}`);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail.msg,
          };
      } else return { error: "Something went wrong" };
    }
  };

  AddNewRole = async (payload) => {
    try {
      const resp = await axios.post(`/admin/roles`, payload);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  UpdateRole = async (role_id, payload) => {
    try {
      const resp = await axios.patch(
        `/admin/roles/${role_id}`,
        payload
      );
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail,
          };
      } else return { error: "Something went wrong" };
    }
  };

  DeleteRole = async (role_id) => {
    try {
      const resp = await axios.delete(`/admin/roles/${role_id}`);
      return resp;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 422) {
          localStorage.clear();
        } else
          return {
            error: error.response.data.detail.msg,
          };
      } else return { error: "Something went wrong" };
    }
  };
}
export default new AdminData();
