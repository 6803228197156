import React, { Fragment, useState, useEffect } from "react";
import { ButtonBase } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import DateRangePicker from "../../../Atoms/DateRangePicker";
import TicketsCard from "../../../Atoms/TicketsCard";
import topStatsData from "../../../Data/topStatsData";
import YesNoStatsData from "../../../Data/YesNoStatsData";
import casesTrackerData from "../../../Data/casesTrackerData";
import BasicColumnChart from "../../../Atoms/BasicColumnChart";
import BasicPieChart from "../../../Atoms/BasicPieChart";
import PieChartsData from "../../../Data/PieChartsData";
import HomePageApi from "../../../API/HomePageApi";
import asthmaIcon from "../../../Assets/Images/Icon/asthmaIcon.svg";
import tbIcon from "../../../Assets/Images/Icon/tbIcon.svg";
import copdIcon from "../../../Assets/Images/Icon/copdIcon.svg";
import healthyIcon from "../../../Assets/Images/Icon/healthyIcon.svg";
import { Box, Button, Skeleton } from "@mui/material";
import BasicFacilityColumnChart from "../../../Atoms/BasicFacilityColumnChart";
import YesNoChart from "../../../Atoms/YesNoChart";
import AgeBarChart from "../../../Atoms/AgeBarChart";
import ageStatsData from "../../../Data/ageStatsData";
import FeaturesBarChart from "../../../Atoms/FeaturesBarChart";
import columnFeaturesData from "../../../Data/columnFeaturesData";
// import bmiData from "../../../Data/bmiData";
// import BmiChart from "../../../Atoms/BmiChart";
import moment from "moment";
import DailyCaseTracker from "../../../Atoms/DailyCaseTracker";

export default function Index(props) {
  const {
    facilityId,
    userRole,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    medicalStatus,
    setmedicalStatus,
  } = props;
  const [ticketsData, setticketsData] = useState();
  const [ApiData, setApiData] = useState();
  const [bmiApiData, setbmiApiData] = useState([]);
  const [BinaryStatsApi, setBinaryStatsApi] = useState();
  const [trackerStats, setTrackerStats] = useState();
  const [totalPatients, setTotalPatients] = useState();
  const [totalSamples, setTotalSamples] = useState();
  const [multiselectfeature, setmultiselectFeature] = useState("gender");
  const [singleFeature, setsingleFeature] = useState("cough_frq");
  const [render5, setrender5] = useState([1, 2, 3, 4, 5]);
  const [trackerchartLoading, settrackerchartLoading] = useState(false);
  const [filter_by, setfilter_by] = useState("year");
  const [TrackApiStartDate, setTrackApiStartDate] = useState("");
  const [graphBy, setGraphBy] = useState("info");
  const [ticketsLoading, setticketsLoading] = useState(false);

  useEffect(() => {
    setticketsLoading(true);
    HomePageApi.GetTicketsStats(
      facilityId,
      startDate,
      endDate,
      medicalStatus
    ).then((res) => {
      res = res.data;
      setTotalPatients(res.all.patient);
      setTotalSamples(res.all.samples);
      setticketsData(res);
      setticketsLoading(false);
    });
  }, [facilityId, startDate, endDate, medicalStatus]);
  const headingOrder = ["Asthma", "Pulmonary TB", "COPD", "Healthy"];

  const total = {
    id: 0,
    bgColor: "#FFFFFF",
    txtColor: "#000000",
    brdrColor: "#E4E4E4",
    icon: "",
    heading: "Total",
    participantValue: totalPatients,
    sampleValue: totalSamples,
  };

  const filteredSampData =
    ticketsData &&
    ticketsData.sample_count?.filter(
      (item) => item.tb_type && item.tb_type === "Pulmonary TB"
    );
  const filteredPatData =
    ticketsData &&
    ticketsData.patient_count?.filter(
      (item) => item.tb_type && item.tb_type == "Pulmonary TB"
    );

  const ticketsDatafinal = headingOrder?.map((heading, index) => {
    const sampItem =
      filteredSampData &&
      // filteredSampData.find((item) => item.tb_type === heading);
      filteredSampData.find((item) => (item.tb_type === heading) === "");

    const patItem =
      filteredPatData &&
      filteredPatData.find((item) => item.tb_type === heading);

    let bgColor = "";
    let txtColor = "";
    let icon = "";

    // Set colors and icon based on tb_type
    switch (heading) {
      case "Asthma":
        bgColor = "#E8F7FF";
        txtColor = "#49BEFF";
        icon = asthmaIcon;
        break;
      case "Pulmonary TB":
        bgColor = "#FEF5E5";
        txtColor = "#FFAE1F";
        icon = tbIcon;
        break;
      case "COPD":
        bgColor = "#ECF2FF";
        txtColor = "#5D87FF";
        icon = copdIcon;
        break;
      case "Healthy":
        bgColor = "#E6FFFA";
        txtColor = "#13DEB9";
        icon = healthyIcon;
        break;
      default:
        // Set default colors and icon
        bgColor = "#FFFFFF";
        txtColor = "#000000";
        icon = "";
        break;
    }

    return {
      id: index + 1,
      bgColor,
      txtColor,
      brdrColor: "#FFFFFF",
      icon,
      heading,
      participantValue: patItem ? patItem.count : 0,
      sampleValue: sampItem ? sampItem.count : 0,
    };
  });

  ticketsDatafinal.unshift(total);

  // end tickets portion

  // topStatsData portion

  useEffect(() => {
    HomePageApi.GetparticipantsStats(
      facilityId,
      startDate,
      endDate,
      medicalStatus,
      graphBy
    ).then((res) => {
      res = res.data;
      setApiData(res);
    });
  }, [facilityId, startDate, endDate, medicalStatus, graphBy]);

  //GetCasesTrackerStats

  useEffect(() => {
    settrackerchartLoading(true);
    HomePageApi.GetCasesTrackerStats(
      filter_by,
      facilityId,
      TrackApiStartDate,
      medicalStatus,
      graphBy
    ).then((res) => {
      const apiData = res.data;
      setTrackerStats([]);
      setTrackerStats(apiData);
      settrackerchartLoading(false);
    });
  }, [facilityId, TrackApiStartDate, medicalStatus, graphBy]);

  useEffect(() => {
    HomePageApi.GetBinaryFeaturesStats(
      facilityId,
      startDate,
      endDate,
      medicalStatus
    ).then((res) => {
      const apiData = res.data;
      setBinaryStatsApi(apiData);
    });
  }, [facilityId, startDate, endDate, medicalStatus]);

  //GetPieFeaturesStats

  //GetBmiStats

  // useEffect(() => {
  //   HomePageApi.GetBmiStats(facilityId, startDate, endDate, medicalStatus).then(
  //     (res) => {
  //       res = res.data;
  //       setbmiApiData(res);
  //     }
  //   );
  // }, [facilityId, startDate, endDate, medicalStatus]);

  return (
    <Fragment>
      {/* Date Filter */}
      {/* <div className="text-end">
        <DateRangePicker
          lastWeek={true}
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </div> */}
      {/* ticketsData */}
      {!ticketsLoading ? (
        ticketsData?.patient_count?.length > 0 ? (
          <div className="grid-5">
            {/* {ticketsDatafinal?.map((each) => {
              return (
                <TicketsCard
                  facilityId={facilityId}
                  userRole={userRole}
                  ticketsDatafinal={ticketsDatafinal}
                  key={each.id}
                  bgColor={each.bgColor}
                  txtColor={each.txtColor}
                  brdrColor={each.brdrColor}
                  sampleValue={each.sampleValue}
                  setmedicalStatus={setmedicalStatus}
                  participantValue={each.participantValue}
                  heading={each.heading}
                  icon={each.icon}
                  medicalStatus={medicalStatus}
                />
              );
            })} */}
            <ButtonBase
              onClick={(e) => {
                window.location.href = "/UsersTable";
              }}
              className={`ticket-cards row ${
                medicalStatus === "COVID" ? "selected-medicalStatus" : null
              }`}
              key={5}
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <div
                className="col-12 h-53 d-flex flex-column text-center fs-24 poppins-Med"
                style={{
                  color: "#000000",
                  borderBottom: `2px solid #E4E4E4`,
                }}
              >
                <h2 className="my-auto">TB Data</h2>
              </div>
              <div className="d-inline-flex w-100">
                <div
                  className="w-50 d-flex flex-column text-center pt-2 pb-0"
                  style={{
                    color: "#000000",
                    borderRight: `2px solid #E4E4E4`,
                  }}
                >
                  <h4 className="text-dark pt-2">Participants</h4>
                  <Fade delay={1e1} cascade damping={1e-1}>
                    <h4 className="fw-bold">825</h4>
                  </Fade>
                </div>
                <div
                  className="w-50 d-flex flex-column text-center pt-2"
                  style={{ color: "#000000" }}
                >
                  <h4 className="text-dark pt-2">Samples</h4>
                  <Fade delay={1e1} cascade damping={1e-1}>
                    <h4 className="fw-bold">2475</h4>
                  </Fade>
                </div>
              </div>
            </ButtonBase>
            <ButtonBase
              onClick={(e) => {
                window.location.href = "/covid-data";
              }}
              className={`ticket-cards row ${
                medicalStatus === "COVID" ? "selected-medicalStatus" : null
              }`}
              key={5}
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <div
                className="col-12 h-53 d-flex flex-column text-center fs-24 poppins-Med"
                style={{
                  color: "#000000",
                  borderBottom: `2px solid #E4E4E4`,
                }}
              >
                <h2 className="my-auto">SARS-CoV-2 Data</h2>
              </div>
              <div className="d-inline-flex w-100">
                <div
                  className="w-50 d-flex flex-column text-center pt-2 pb-0"
                  style={{
                    color: "#000000",
                    borderRight: `2px solid #E4E4E4`,
                  }}
                >
                  <h4 className="text-dark pt-2">Participants</h4>
                  <Fade delay={1e1} cascade damping={1e-1}>
                    <h4 className="fw-bold">825</h4>
                  </Fade>
                </div>
                <div
                  className="w-50 d-flex flex-column text-center pt-2"
                  style={{ color: "#000000" }}
                >
                  <h4 className="text-dark pt-2">Samples</h4>
                  <Fade delay={1e1} cascade damping={1e-1}>
                    <h4 className="fw-bold">825</h4>
                  </Fade>
                </div>
              </div>
            </ButtonBase>
          </div>
        ) : (
          <p className="fs-20 fw-700 poppins-Med text-center p-5">
            No Data Available
          </p>
        )
      ) : (
        <div className="grid-5">
          {render5.map((index) => {
            return (
              <Skeleton
                id={index}
                key={index}
                sx={{
                  minHeight: "117px",
                  margin: "0px",
                  padding: "0px",
                  transform: "scale(1)",
                }}
              />
            );
          })}
        </div>
      )}
      {/* topStatsData */}
      <div className="graph-cards my-5 position-relative">
        {/* <Box sx={{ display: "flex" }}>
          <Box className="switch-box">
            <Box
              className="switch"
              style={{
                transform: `translateX(${graphBy === "info" ? 0 : "130px"})`,
              }}
            />
            <Button
              disableRipple
              variant="text"
              sx={{
                color: graphBy === "info" ? "#ffffff" : "#49BEFF",
              }}
              onClick={() => setGraphBy("info")}
            >
              Participants
            </Button>
            <Button
              disableRipple
              variant="text"
              sx={{
                color: graphBy === "visit" ? "#ffffff" : "#49BEFF",
              }}
              onClick={() => setGraphBy("visit")}
            >
              Visits
            </Button>
          </Box>
        </Box> */}
        {/* <div className="form-group col-3 mb-3 ms-auto text-end">
          <select
            className="form-control form-control-lg"
            id="feature"
            onChange={(e) => {
              setGraphBy(e.target.value);
            }}
          >
            <option value="info">Patients</option>
            <option value="visit">Visits</option>
          </select>
        </div> */}
        <div className="grid-2">
          {/* {topStatsData &&
            topStatsData?.map((each) => {
              return facilityId == 0 || facilityId == null ? (
                <BasicColumnChart
                  userRole={userRole}
                  facilityId={facilityId}
                  id={each.id}
                  cardHeight={each.cardHeight}
                  chartHeight={each.chartHeight}
                  heading={each.heading}
                  y_title={each.y_title}
                  x_title={each.x_title}
                  colColors={each.colColors}
                  xCategories={each.xCategories}
                  ApiData={ApiData}
                  graphBy={graphBy}
                />
              ) : (
                <BasicFacilityColumnChart
                  userRole={userRole}
                  facilityId={facilityId}
                  ApiData={ApiData}
                  id={each.id}
                  cardHeight={each.cardHeight}
                  chartHeight={each.chartHeight}
                  heading={each.heading}
                  y_title={each.y_title}
                  x_title={each.x_title}
                  colColors={each.colColors}
                  xCategories={each.xCategories}
                  graphBy={graphBy}
                />
              );
            })} */}
        </div>
        {/* DailyCaseTracker */}
        <div className="my-5">
          {casesTrackerData &&
            casesTrackerData.map((each) => {
              return (
                <DailyCaseTracker
                  endDate={endDate}
                  userRole={userRole}
                  ApiData={trackerStats ? trackerStats : []}
                  setApiData={setTrackerStats}
                  facilityId={facilityId}
                  id={each.id}
                  cardHeight={each.cardHeight}
                  chartHeight={each.chartHeight}
                  heading={
                    filter_by === "year"
                      ? each.heading + " (Yearly Tracker) "
                      : filter_by === "month"
                      ? each.heading +
                        " (Monthly Tracker) " +
                        moment(TrackApiStartDate).format("YYYY")
                      : each.heading +
                        " (Daily Tracker) " +
                        moment(TrackApiStartDate).format("MMM")
                  }
                  y_title={each.y_title}
                  x_title={each.x_title}
                  series={each.series}
                  colColors={each.colColors}
                  // xCategories={each.xCategories}
                  stackedChart={each.stackedChart}
                  startDate={TrackApiStartDate}
                  setStartDate={setTrackApiStartDate}
                  filter_by={filter_by}
                  setfilter_by={setfilter_by}
                  setisLoading={settrackerchartLoading}
                  isLoading={trackerchartLoading}
                  graphBy={graphBy}
                  setGraphBy={setGraphBy}
                />
              );
            })}
        </div>
      </div>
      {/* YesNoStatsData */}
      <div className="my-5">
        {YesNoStatsData?.map((each) => {
          return (
            <YesNoChart
              id={each.id}
              userRole={userRole}
              ApiData={BinaryStatsApi}
              cardHeight={each.cardHeight}
              chartHeight={each.chartHeight}
              heading={each.heading}
              y_title={each.y_title}
              x_title={each.x_title}
              series={each.series}
              colColors={each.colColors}
              xCategories={each.xCategories}
            />
          );
        })}
      </div>
      {/* PieChartsData */}
      <div className="grid-3 graph-cards my-5">
        {PieChartsData?.map((each) => {
          return (
            <BasicPieChart
              userRole={userRole}
              feature={each.feature}
              startDate={startDate}
              endDate={endDate}
              facilityId={facilityId}
              // ApiData={PieStatsApi}
              id={each.id}
              cardHeight={each.cardHeight}
              chartHeight={each.chartHeight}
              heading={each.heading}
              y_title={each.y_title}
              x_title={each.x_title}
              series={each.series}
              colColors={each.colColors}
              xCategories={each.xCategories}
              medicalStatus={medicalStatus}
            />
          );
        })}
      </div>
      {/* BmiChart */}
      {/* <div className="my-5">
        <BmiChart
          id={null}
          userRole={userRole}
          facilityId={facilityId}
          cardHeight={345}
          chartHeight={325}
          heading="BMI"
          y_title=""
          x_title=""
          colColors={["#49BEFF", "#FFAE1F", "#5D87FF", "#13DEB9"]}
          xCategories={[
            "Underweight",
            "Normal",
            "Overweight",
            "Obesity",
            "Not Label",
          ]}
          ApiData={bmiApiData}
        />
      </div> */}
      {/*AgeBarChart + coughing/shortness of breath*/}
      <div className={`${facilityId === 0 ? "" : "grid-2"}`}>
        <div className="graph-cards my-5">
          {facilityId === 0 && (
            <div className="row">
              <div className="form-group col-3 mb-3 ms-auto text-end">
                <select
                  className="form-control form-control-lg"
                  id="feature"
                  onChange={(e) => {
                    setsingleFeature(e.target.value);
                  }}
                >
                  <option value="cough_frq">Coughing</option>
                  <option value="shortness_of_breath_for">
                    Shortness of Breath
                  </option>
                  <option value="patient_type">Patient Type</option>
                </select>
              </div>
            </div>
          )}
          <div className={`${facilityId === 0 ? "grid-2" : ""}`}>
            {facilityId === 0
              ? columnFeaturesData?.map((each) => {
                  return (
                    <FeaturesBarChart
                      id={each.id}
                      startDate={startDate}
                      endDate={endDate}
                      facilityId={facilityId !== 0 ? facilityId : each.facility}
                      cardHeight={each.cardHeight}
                      chartHeight={each.chartHeight}
                      heading={userRole == 4 ? each.maskHeading : each.heading}
                      y_title={each.y_title}
                      x_title={each.x_title}
                      singleFeature={singleFeature}
                      setsingleFeature={setsingleFeature}
                      medicalStatus={medicalStatus}
                      colColors={each.colColors}
                      stackedChart={each.stackedChart}
                    />
                  );
                })
              : columnFeaturesData
                  ?.filter((each) => each.facility === facilityId)
                  ?.map((each) => (
                    <FeaturesBarChart
                      key={each.id}
                      id={each.id}
                      singleFeature={singleFeature}
                      setsingleFeature={setsingleFeature}
                      medicalStatus={medicalStatus}
                      colColors={each.colColors}
                      facilityId={facilityId !== 0 ? facilityId : each.facility}
                      stackedChart={each.stackedChart}
                      onlyFacility={true}
                      startDate={startDate}
                      endDate={endDate}
                      cardHeight={each.cardHeight}
                      chartHeight={each.chartHeight}
                      heading={userRole === 4 ? each.maskHeading : each.heading}
                      y_title={each.y_title}
                      x_title={each.x_title}
                    />
                  ))}
          </div>
        </div>
        <div className="graph-cards my-5">
          {facilityId === 0 && (
            <div className="row">
              <p className="fs-20 fw-700 poppins-Med col-4 ">
                {`${multiselectfeature
                  .toUpperCase()
                  .replace("_", " ")} Stats by Age`}
              </p>
              <div className="form-group col-3 mb-3 ms-auto text-end">
                <select
                  className="form-control form-control-lg"
                  id="feature"
                  onChange={(e) => {
                    setmultiselectFeature(e.target.value);
                  }}
                >
                  <option value="gender">Gender</option>
                  <option value="tb_type">TB type</option>
                  <option value="marital_status">Marital status</option>
                  <option value="ventilated">Ventilated room</option>
                  <option value="smoking_history">Smoking history</option>
                  <option value="weight_loss">Weight loss</option>
                  <option value="fever">Fever</option>
                  <option value="coughing_blood">Coughing blood</option>
                  <option value="loss_appetite">Loss of appetite</option>
                  {/* <option value="patient_type">Patient Type</option> */}
                </select>
              </div>
            </div>
          )}
          <div className={`${facilityId === 0 ? "grid-2" : ""}`}>
            {facilityId === 0
              ? ageStatsData?.map((each) => {
                  return (
                    <AgeBarChart
                      id={each.id}
                      startDate={startDate}
                      endDate={endDate}
                      facilityId={facilityId !== 0 ? facilityId : each.facility}
                      cardHeight={each.cardHeight}
                      chartHeight={each.chartHeight}
                      heading={userRole == 4 ? each.maskHeading : each.heading}
                      y_title={each.y_title}
                      x_title={each.x_title}
                      multiselectfeature={multiselectfeature}
                      setmultiselectFeature={setmultiselectFeature}
                      medicalStatus={medicalStatus}
                      colColors={each.colColors}
                      stackedChart={each.stackedChart}
                    />
                  );
                })
              : ageStatsData
                  ?.filter((each) => each.facility !== facilityId)
                  ?.map((each) => (
                    <AgeBarChart
                      key={each.id}
                      id={each.id}
                      startDate={startDate}
                      endDate={endDate}
                      facilityId={facilityId !== 0 ? facilityId : each.facility}
                      cardHeight={each.cardHeight}
                      chartHeight={each.chartHeight}
                      heading={userRole === 4 ? each.maskHeading : each.heading}
                      y_title={each.y_title}
                      x_title={each.x_title}
                      multiselectfeature={multiselectfeature}
                      setmultiselectFeature={setmultiselectFeature}
                      medicalStatus={medicalStatus}
                      colColors={each.colColors}
                      stackedChart={each.stackedChart}
                      onlyFacility={true}
                    />
                  ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
