import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { Fade } from "react-awesome-reveal";

export default function DailyAnnotatedChart(props) {
  const { ApiData } = props;

  const [xCategories, setxCategories] = useState([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (ApiData) {
      // Sort data by date (if needed)
      const sortedData = ApiData.sort((a, b) => new Date(a.date) - new Date(b.date));

      // Extract dates as categories
      const categories = sortedData.map((entry) => entry.date);
      setxCategories(categories);

      // Prepare series data
      const dataSeries = sortedData.map((entry) => entry.count);
      setSeries([
        {
          name: "Recordings Count",
          data: dataSeries,
          color: "#49beff", // Customize the color as needed
        },
      ]);
    }
  }, [ApiData]);

  const options = {
    chart: {
      type: "bar",
      height: 450,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
        autoScaleXaxis: false,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: "10",
            },
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 2,
        borderRadiusWhenStacked: "all",
      },
    },
    title: {
      text: "Annotated Data Tracking",
      align: "left",
      style: {
        fontSize: "24px",
        fontWeight: "700",
        fontFamily: "poppins",
        color: "#000000",
      },
    },
    xaxis: {
      type: "datetime",
      categories: xCategories,
      title: {
        text: "Dates",
        style: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
        tickAmount: 4,
      },
    },
    yaxis: {
      title: {
        text: "Recordings Count",
        style: {
          fontSize: "12px",
          fontFamily: "Poppins",
          fontWeight: 400,
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      offsetY: -30,
      horizontalAlign: "right",
      fontSize: "12px",
      fontFamily: "Poppins",
      fontWeight: 400,
      markers: {
        radius: 10,
        offsetY: 2,
      },
    },
    fill: {
      opacity: 1,
    },
  };

  return (
    <div style={{ height: "400px" }}>
      <div id="chart">
        <Fade delay={1e2}>
          <ReactApexChart
            options={options}
            series={series}
            type="bar"
            height={350}
          />
        </Fade>
      </div>
    </div>
  );
}
