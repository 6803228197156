import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { Box, Chip, CircularProgress, Fab } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Add, FilterAltOff } from "@mui/icons-material";

export default function TableComponent(props) {
  const {
    datefilters,
    value,
    Data,
    breathingCount,
    coughingCount,
    fileCount,
    count,
    counts,
    columnDefs,
    sendingReq,
    details,
    buttonText,
    setAddUserModal,
    testReportCount,
    formCount,
    isCounting,
    isNotCounting,
    consentFormCount,
  } = props;
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [state, setstate] = useState(true);
  const [states, setstates] = useState(true);
  const [filteredArray, setfilteredArray] = useState([]);
  const location = useLocation();
  const [search, setSearch] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (gridApi) {
      datefilters(startDate, endDate, gridApi, getFilterType);
      gridApi.api.onFilterChanged();
      setstate(!state);
    }
  }, [startDate, endDate]);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);

  function handleNullValues(params) {
    if (params.value) return params.value;
    else return "-";
  }

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 130,
      sortable: true,
      resizable: true,
      menuTabs: ["filterMenuTab", "generalMenuTab", "columnsMenuTab"],
      cellRenderer: handleNullValues,
      // editable: true
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "Group",
      field: "athlete",
      minWidth: 220,
      cellRenderer: "agGroupCellRenderer",
    };
  }, []);

  const getFilterType = () => {
    if (startDate !== "" && endDate !== "") {
      return "inRange";
    }
  };

  const onFilterTextBoxChanged = useCallback(() => {
    localStorage.setItem(
      `quick_${value}`,
      document.getElementById("filter-text-box").value
    );
    gridRef.current.api.setQuickFilter(localStorage.getItem(`quick_${value}`));
  }, []);

  useEffect(() => {
    if (gridApi) {
      document.getElementById("filter-text-box").value = localStorage.getItem(
        `quick_${value}`
      );
      gridApi.api.setQuickFilter(" ");
      gridApi.api.setQuickFilter(localStorage.getItem(`quick_${value}`));
    }
  }, [gridApi, Data]);

  useEffect(() => {
    if (search) {
      inputRef.current.focus();
    }
  }, [search]);

  const statusBar = useMemo(() => {
    if (!count) {
      return {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          { statusPanel: "agTotalRowCountComponent", align: "left" },
          { statusPanel: "agFilteredRowCountComponent", align: "center" },
          { statusPanel: "agSelectedRowCountComponent", align: "center" },
          { statusPanel: "agAggregationComponent", align: "center" },
        ],
      };
    }
  }, []);

  const sidebar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressValues: true,
          },
        },
      ],
      defaultToolPanel: ["filters", "columns"],
    };
  }, []);

  const [filterstate, setfilterState] = useState(false);
  const filterview = () => {
    setfilterState(!filterstate);
  };

  useEffect(() => {
    setstates(!states);
  }, [counts]);

  const func = () => {
    setstate(!state);
  };

  var savedFilterModel = null;
  useEffect(() => {
    if (gridApi) {
      savedFilterModel = gridApi.api.getFilterModel();
      localStorage.setItem(value, JSON.stringify(savedFilterModel));
    }
  }, [state]);

  useEffect(() => {
    if (gridApi) {
      const savedfiltermodel = localStorage.getItem(value);
      gridApi.api.setFilterModel(JSON.parse(savedfiltermodel));
      gridApi.api.setQuickFilter("gtrrgrgrg");
      gridApi.api.setQuickFilter(localStorage.getItem(`quick_${value}`));
    }
  }, [gridApi, Data]);
  const loggedin_userId = localStorage.getItem("userID");

  useEffect(() => {
    let arr = [];

    for (let i in Data) {
      if (location.pathname == "/Ratings") {
        if (
          Data[i].user_id1 == loggedin_userId ||
          Data[i].user_id2 == loggedin_userId ||
          Data[i].user_id3 == loggedin_userId ||
          Data[i].lead_rc_id == loggedin_userId
        ) {
          arr.push(Data[i]);
        }
      } else arr.push(Data[i]);
    }
    if (arr !== null && !arr.includes("harry")) {
      for (let i in arr) {
        arr[i].sNo = Number(i) + 1;
      }
    }
    if (arr.includes("harry")) {
      arr = [];
    }
    setfilteredArray(arr);
  }, [Data]);

  return (
    <>
      <div style={{ containerStyle }} className="themeContainer">
        <Box className={`py-4 mt-1 row`}>
          {details && !isCounting ? (
            <div className="col-md-9">
              {fileCount !== 0 && (
                <Chip
                  variant="outlined"
                  className="fs-16 me-4 rounded-4"
                  label={`File: ${fileCount || "_"}`}
                  sx={{
                    borderRadius: "16px",
                    fontWeight: "400",
                    color: "#B54708",
                    borderColor: "#B54708",
                    backgroundColor: "#FFFAEB",
                  }}
                />
              )}
              {coughingCount !== 0 && (
                <Chip
                  variant="outlined"
                  className="fs-16 me-4 rounded-4"
                  label={`Coughing: ${coughingCount || "_"}`}
                  sx={{
                    borderRadius: "16px",
                    fontWeight: "400",
                    color: "#B54708",
                    borderColor: "#B54708",
                    backgroundColor: "#FFFAEB",
                  }}
                />
              )}
              {breathingCount !== 0 && (
                <Chip
                  variant="outlined"
                  className="fs-16 me-4 rounded-4"
                  label={`Breathing: ${breathingCount || "_"}`}
                  sx={{
                    borderRadius: "16px",
                    fontWeight: "400",
                    color: "#B54708",
                    borderColor: "#B54708",
                    backgroundColor: "#FFFAEB",
                  }}
                />
              )}
              {testReportCount !== 0 && (
                <Chip
                  variant="outlined"
                  className="fs-16 me-4 rounded-4"
                  label={`Test Report: ${testReportCount || "_"}`}
                  sx={{
                    borderRadius: "16px",
                    fontWeight: "400",
                    color: "#B54708",
                    borderColor: "#B54708",
                    backgroundColor: "#FFFAEB",
                  }}
                />
              )}
              {formCount !== 0 && (
                <Chip
                  variant="outlined"
                  className="fs-16 me-4 rounded-4"
                  label={`Form: ${formCount || "_"}`}
                  sx={{
                    borderRadius: "16px",
                    fontWeight: "400",
                    color: "#B54708",
                    borderColor: "#B54708",
                    backgroundColor: "#FFFAEB",
                  }}
                />
              )}
              {consentFormCount !== 0 && (
                <Chip
                  variant="outlined"
                  className="fs-16 rounded-4"
                  label={`Consent Form: ${consentFormCount || "_"}`}
                  sx={{
                    borderRadius: "16px",
                    fontWeight: "400",
                    color: "#B54708",
                    borderColor: "#B54708",
                    backgroundColor: "#FFFAEB",
                  }}
                />
              )}
            </div>
          ) : isNotCounting ? null : (
            <div className="col-md-9">
              <CircularProgress className="ms-5 text-primry" size={25} />
            </div>
          )}
          <div className="col-md-3 my-auto">
            <input
              autoFocus={search && true}
              ref={inputRef}
              className="form-control"
              type="search"
              placeholder="Search..."
              aria-label="Search"
              onInput={onFilterTextBoxChanged}
              id="filter-text-box"
            />
          </div>
          {buttonText && (
            <div className="col-md-9">
              <button
                className="btn btn-primary text-light fs-16 col-md-2 my-auto"
                onClick={() => setAddUserModal(true)}
              >
                <Add className="mx-2 fs-1" />
                {buttonText}
              </button>
            </div>
          )}
        </Box>
        <div
          className="ag-theme-alpine position-relative"
          style={{ width: "100%", height: `calc(100vh - 180px)` }}
        >
          {sendingReq && (
            <div className="text-center d-flex h-100 flex-column absolute-center-loader transition-loading">
              <div className="my-auto">
                <CircularProgress />
                <p className="fw-bolder fs-16 fw-500 text-center text-dark my-3">
                  wait while data is loading...
                </p>
              </div>
            </div>
          )}
          <AgGridReact
            ref={gridRef}
            rowData={filteredArray}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            animateRows={true}
            rowGroupPanelShow={"always"}
            autoGroupColumnDef={autoGroupColumnDef}
            rowSelection="multiple"
            sideBar={sidebar}
            statusBar={statusBar}
            suppressCsvExport={true}
            suppressExcelExport={true}
          />
        </div>
      </div>
    </>
  );
}
