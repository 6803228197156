import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomCss/datePickerStyles.css";
import moment from "moment";
import calenderIcon from "../Assets/Images/Icon/calender.svg";
import weekIcon from "../Assets/Images/Icon/week.svg";
import monthIcon from "../Assets/Images/Icon/month.svg";
import { Button, IconButton } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function DateRangePicker(props) {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    lastWeek,
    lastMonth,
    setIsloadingSearch,
  } = props;
  const location = useLocation();
  const handleStartDateChange = (date) => {
    setStartDate(date ? moment(date).format("YYYY-MM-DD") : null);
    !location.pathname.includes("/home") && setIsloadingSearch(true);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? moment(date).format("YYYY-MM-DD") : null);
    !location.pathname.includes("/home") && setIsloadingSearch(true);
  };

  const selectLastWeek = () => {
    const today = moment();
    const lastWeekStartDate = today.clone().subtract(1, "week");
    const lastWeekEndDate = today;

    const formattedStartDate = lastWeekStartDate.format("YYYY-MM-DD");
    const formattedEndDate = lastWeekEndDate.format("YYYY-MM-DD");

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  const selectLastMonth = () => {
    const today = moment();
    const lastWeekStartDate = today.clone().subtract(1, "month");
    const lastWeekEndDate = today;

    const formattedStartDate = lastWeekStartDate.format("YYYY-MM-DD");
    const formattedEndDate = lastWeekEndDate.format("YYYY-MM-DD");

    setStartDate(formattedStartDate);
    setEndDate(formattedEndDate);
  };

  const openCalendar = (e, inputId) => {
    e.preventDefault();
    document.getElementById(inputId)?.focus();
  };

  return (
    <div className="d-inline-flex">
      {lastWeek && (
        <>
          <Button className="week-btn h-75 bg-white" onClick={selectLastMonth}>
            <img src={monthIcon} />
          </Button>
          <Button className="week-btn h-75 bg-white" onClick={selectLastWeek}>
            <img src={weekIcon} />
          </Button>
        </>
      )}
      <div className="date-picker-field position-relative">
        <DatePicker
          autoComplete="off"
          id="start-date"
          todayButton="Today"
          isClearable={true}
          selected={startDate ? moment(startDate, "YYYY-MM-DD").toDate() : null}
          onChange={handleStartDateChange}
          selectsStart
          startDate={
            startDate ? moment(startDate, "YYYY-MM-DD").toDate() : null
          }
          endDate={endDate ? moment(endDate, "YYYY-MM-DD").toDate() : null}
          placeholderText="Date"
        />
        <div
          className="date-picker-icon calenderIcon-left"
          onClick={(e) => openCalendar(e, "start-date")}
        >
          <img src={calenderIcon} />
        </div>
      </div>
      <h1 className="mx-3 fw-600 mb-0 mt-2">-</h1>
      <div className="date-picker-field position-relative">
        <DatePicker
          autoComplete="off"
          id="end-date"
          todayButton="Today"
          isClearable={true}
          selected={endDate ? moment(endDate, "YYYY-MM-DD").toDate() : null}
          onChange={handleEndDateChange}
          selectsEnd
          startDate={
            startDate ? moment(startDate, "YYYY-MM-DD").toDate() : null
          }
          endDate={endDate ? moment(endDate, "YYYY-MM-DD").toDate() : null}
          minDate={startDate ? moment(startDate, "YYYY-MM-DD").toDate() : null}
          placeholderText="Date"
        />
        <div
          className="date-picker-icon calenderIcon-right"
          onClick={(e) => openCalendar(e, "end-date")}
        >
          <img src={calenderIcon} />
        </div>
      </div>
    </div>
  );
}
