import * as React from "react";
import { useState, useRef, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PageMainComponent from "./PageMainComponent";
import { Fab, IconButton } from "@mui/material";
import { KeyboardArrowUp } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Index() {
  // const ref = useRef();
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [medicalStatus, setmedicalStatus] = useState("Total");
  const userRole = localStorage.getItem("role");
  // const [pos, setPos] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleTop = () => {
  //   ref.current.scrollTop = 0;
  //   setPos(false);
  // };

  // const handleScroll = () => {
  //   if (ref.current.scrollTop > 50) {
  //     if (!pos) setPos(true);
  //   } else {
  //     if (pos) setPos(false);
  //   }
  // };

  // useEffect(() => {
  //   const temp = ref.current;
  //   temp.addEventListener("scroll", handleScroll);
  //   return () => temp.removeEventListener("scroll", handleScroll);
  // });

  return (
    <div className="secondary-bg" id="top">
      <Box className="container" sx={{ width: "100%" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {/* <Tab className="tab-buttons" label="All" /> */}
          {/* <Tab
            className="tab-buttons"
            label={userRole == 4 ? "Facility 2" : "Mayo"}
          /> */}
          {/* <Tab
            className="tab-buttons"
            label={userRole == 4 ? "Facility 3" : "Gulab Devi"}
          /> */}
        </Tabs>
        <Box>
        <TabPanel value={value} index={0}>
            <PageMainComponent
              facilityId={2}
              userRole={userRole}
              // startDate={startDate}
              setStartDate={setStartDate}
              // endDate={endDate}
              // setEndDate={setEndDate}
              medicalStatus="Pulmonary TB"
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={0}>
            <PageMainComponent
              facilityId={0}
              userRole={userRole}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              medicalStatus={medicalStatus}
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel> */}
          {/* <TabPanel value={value} index={1}>
            <PageMainComponent
              facilityId={2}
              userRole={userRole}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              medicalStatus={medicalStatus}
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel> */}
          {/* <TabPanel value={value} index={2}>
            <PageMainComponent
              facilityId={3}
              userRole={userRole}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              medicalStatus={medicalStatus}
              setmedicalStatus={setmedicalStatus}
            />
          </TabPanel> */}
        </Box>
      </Box>
      {/* <IconButton
          style={{
            position: "fixed",
            bottom: 10,
            right: 10,
            display: pos ? "block" : "none",
          }}
          onClick={handleTop}
        >
          Up
        </IconButton> */}
      {/* <TabPanel value={value} index={1}>
              <PageMainComponent
                facilityId={1}
                userRole={userRole}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                medicalStatus={medicalStatus}
                setmedicalStatus={setmedicalStatus}
              />
            </TabPanel> */}
    </div>
  );
}
