import React, { useState, useEffect } from "react";
import Img1 from "../../Assets/Images/login_img.svg";
import Img2 from "../../Assets/Images/aiforlifelogo.svg";
import { useNavigate } from "react-router-dom";
import Account from "../../API/Account";
import Auth from "../../Middleware/Auth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, CircularProgress, Snackbar } from "@mui/material";

export default function Index() {
  const history = useNavigate();
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const [sendingReq, setsendingReq] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [isrememberme, setisrememberme] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  const handleSubmit = async (e) => {
    setsendingReq(true);
    e.preventDefault();
    const res = Account.LoginApi(phone, password);
    res.then((value) => {
      setsendingReq(false);
      if (value !== undefined && value.data.token) {
        localStorage.setItem("userIsLoggedIn", true);
        goToDashboard();
        localStorage.setItem("accessToken", value.data.token);
        localStorage.setItem("role", value.data.role);
        Auth.Login();
      } else seterrorMessage(true);
    });
  };

  const goToDashboard = () => {
    history("/home");
    window.location.reload();
  };

  return (
    <div className="row container mx-auto vh-100">
      <div className="col-12 col-md-6 d-none d-md-block">
        <div className="h-100 d-flex">
          <div className="h-100 d-flex mx-auto my-auto">
            <img src={Img1} className="img-fill" />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="h-100 mx-auto d-flex w-42 p-3">
          <div className="mx-auto col-8 mx-auto text-center my-auto">
            <div className="mx-auto mb-5 w-35">
              <img src={Img2} className="mb-5" style={{ width: "30%" }} />
              <p className="Lato fs-36 text-center fw-bold text-grey-black mb-4">
                Login to your account
              </p>
            </div>
            <form className="w-100" type="submit" onSubmit={handleSubmit}>
              <div className="text-start mb-1">
                <label htmlFor="phone">Login ID</label>
                <input
                  value={phone}
                  type="tel"
                  name="phone"
                  onChange={(e) => setphone(e.target.value.toString())}
                  placeholder="mail@abc.com"
                />
              </div>
              <div className="position-relative text-start mb-5">
                <label htmlFor="password">Password</label>
                <input
                  className="mb-0"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={(e) => setpassword(e.target.value.toString())}
                  placeholder="*********"
                />
                <div
                  className="eye-div bg-scndry"
                  onClick={() => setshowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <VisibilityOff className="d-flex mt-3 mx-auto fs-1 text-blue" />
                  ) : (
                    <Visibility className="d-flex mt-3 mx-auto fs-1 text-blue" />
                  )}
                </div>
              </div>
              {/* <div className="w-100 d-inline-flex mt-2">
                <div className="d-inline-flex w-100 me-auto">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={isrememberme}
                      name="isrememberme"
                      onChange={(e) => setisrememberme(e.target.value)}
                    />
                    <p className="ps-3 pt-2 text-secondary fs-12 Lato text-nowrap">
                      Remember Me
                    </p>
                  </div>
                </div>
                <p className="Latto fs-12 fw-600 text-primary text-nowrap">
                  Forgot Password?
                </p>
              </div> */}
              <button
                type="submit"
                className="btn-primary Lato fs-18 col-md-4 p-3 text-light fw-800 w-100"
              >
                {sendingReq ? <CircularProgress color="inherit" /> : "Login"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={errorMessage ? true : false}
        onClose={() => seterrorMessage(false)}
        autoHideDuration={3000}
      >
        <Alert severity="warning" sx={{ width: 500, fontSize: "16px" }}>
          wrong email or password
        </Alert>
      </Snackbar>
    </div>
  );
}
