import axios from "axios";

class HomePageApi {
  constructor() {
    this.state = {
      open: false,
    };
  }

  constructApiUrl = (
    baseApi,
    facilityId,
    startDate,
    endDate,
    medicalStatus
  ) => {
    let api = baseApi;
    if (medicalStatus == "Total") medicalStatus = null;
    else medicalStatus = medicalStatus;

    if (facilityId !== undefined && facilityId !== null) {
      if (facilityId === 0) {
        if (medicalStatus) {
          if (startDate && endDate) {
            api += `?end_date=${endDate}&start_date=${startDate}&disease=${medicalStatus}`;
          } else {
            api += `?disease=${medicalStatus}`;
          }
        }
        if (startDate && endDate) {
          if (medicalStatus) {
            api += `?end_date=${endDate}&start_date=${startDate}&disease=${medicalStatus}`;
          } else api += `?end_date=${endDate}&start_date=${startDate}`;
        }
      } else {
        if (medicalStatus) {
          if (startDate && endDate) {
            api += `?facility=${facilityId}&disease=${medicalStatus}&end_date=${endDate}&start_date=${startDate}`;
          } else {
            api += `?facility=${facilityId}&disease=${medicalStatus}`;
          }
        } else if (startDate && endDate) {
          if (medicalStatus) {
            api += `?facility=${facilityId}&disease=${medicalStatus}&end_date=${endDate}&start_date=${startDate}`;
          } else
            api += `?facility=${facilityId}&end_date=${endDate}&start_date=${startDate}`;
        } else {
          api += `?facility=${facilityId}`;
        }
        // if (startDate && endDate) {
        //   api += `?end_date=${endDate}&start_date=${startDate}&facility=${facilityId}`;
        // } else {
        //   api += `?facility=${facilityId}`;
        // }
      }
    } else {
      if (startDate && endDate) {
        api += `?end_date=${endDate}&start_date=${startDate}`;
      }
    }
    return api;
  };

  constructtrackApiUrl = (
    baseApi,
    facilityId,
    trackApiStartDate,
    medicalStatus
  ) => {
    let api = baseApi;
    let params = new URLSearchParams();

    if (medicalStatus && medicalStatus !== "Total") {
      params.append("disease", medicalStatus);
    }

    if (trackApiStartDate) {
      params.append("start_date", trackApiStartDate);
    }

    if (facilityId !== undefined && facilityId !== null) {
      if (facilityId !== 0) {
        params.append("facility", facilityId);
      }
    }

    const queryString = params.toString();
    if (queryString) {
      api += `&${queryString}`;
    }

    return api;
  };

  // constructtrackApiUrl = (
  //   baseApi,
  //   facilityId,
  //   TrackApiStartDate,
  //   medicalStatus
  // ) => {
  //   let api = baseApi;
  //   if (medicalStatus == "Total") medicalStatus = null;
  //   else medicalStatus = medicalStatus;

  //   if (facilityId !== undefined && facilityId !== null) {
  //     if (facilityId === 0) {
  //       if (medicalStatus) {
  //         if (TrackApiStartDate) {
  //           api += `&start_date=${TrackApiStartDate}&disease=${medicalStatus}`;
  //         } else {
  //           api += `&disease=${medicalStatus}`;
  //         }
  //       }
  //       if (TrackApiStartDate) {
  //         if (medicalStatus) {
  //           api += `&start_date=${TrackApiStartDate}&disease=${medicalStatus}`;
  //         } else api += `&start_date=${TrackApiStartDate}`;
  //       }
  //     } else {
  //       if (TrackApiStartDate) {
  //         api += `&start_date=${TrackApiStartDate}&facility=${facilityId}`;
  //       } else {
  //         api += `&facility=${facilityId}`;
  //       }
  //     }
  //   } else {
  //     if (TrackApiStartDate) {
  //       api += `&start_date=${TrackApiStartDate}`;
  //     }
  //   }
  //   return api;
  // };

  makeGetRequest = async (api) => {
    try {
      const response = await axios.get(api);
      return response;
    } catch (error) {
      console.log(error);
      if (
        error.response &&
        error.response.request &&
        error.response.request.statusText === "Unauthorized"
      ) {
        console.log(error);
      }
      throw error;
    }
  };

  GetTicketsStats = (facilityId, startDate, endDate, medicalStatus) => {
    const baseApi = "/portal/patient/stat";
    const api = this.constructApiUrl(
      baseApi,
      facilityId,
      startDate,
      endDate,
      medicalStatus
    );
    return this.makeGetRequest(api);
  };

  GetparticipantsStats = (
    facilityId,
    startDate,
    endDate,
    medicalStatus,
    graphBy
  ) => {
    const baseApi = "/portal/total/stat";
    const api = this.constructApiUrl(
      baseApi,
      facilityId,
      startDate,
      endDate,
      medicalStatus
    );
    return this.makeGetRequest(
      api === baseApi
        ? api + `?graph_by=${graphBy}`
        : api + `&graph_by=${graphBy}`
    );
  };

  GetCasesTrackerStats = (
    filter_by,
    facilityId,
    TrackApiStartDate,
    medicalStatus,
    graphBy
  ) => {
    const baseApi = `/portal/case/track?filter_by=${filter_by}&graph_by=${graphBy}`;
    const api = this.constructtrackApiUrl(
      baseApi,
      facilityId,
      TrackApiStartDate,
      medicalStatus
    );
    return this.makeGetRequest(api);
  };

  GetBinaryFeaturesStats = (facilityId, startDate, endDate, medicalStatus) => {
    const baseApi = "/portal/binary/stat";
    const api = this.constructApiUrl(
      baseApi,
      facilityId,
      startDate,
      endDate,
      medicalStatus
    );
    return this.makeGetRequest(api);
  };

  GetPieFeaturesStats = (
    facilityId,
    startDate,
    endDate,
    medicalStatus,
    feature
  ) => {
    const baseApi = `/portal/feature/${feature}/stat`;
    const api = this.constructApiUrl(
      baseApi,
      facilityId,
      startDate,
      endDate,
      medicalStatus
    );
    return this.makeGetRequest(api);
  };
  GetAgeFeaturesStats = (
    facilityId,
    startDate,
    endDate,
    medicalStatus,
    feature
  ) => {
    const baseApi = `/portal/age/${feature}/stat`;
    const api = this.constructApiUrl(
      baseApi,
      facilityId,
      startDate,
      endDate,
      medicalStatus
    );
    return this.makeGetRequest(api);
  };
  // GetBmiStats = (facilityId, startDate, endDate, medicalStatus) => {
  //   const baseApi = `/portal/bmi/stat`;
  //   const api = this.constructApiUrl(
  //     baseApi,
  //     facilityId,
  //     startDate,
  //     endDate,
  //     medicalStatus
  //   );
  //   return this.makeGetRequest(api);
  // };
}

export default new HomePageApi();
