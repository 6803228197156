const casesTrackerData = [
  {
    id: 1,
    heading: "Daily Reported Cases",
    x_title: "Date",
    y_title: "Number of Participants",
    cardHeight: 345,
    chartHeight: 325,
    stackedChart: true,
    colColors: [
      "#49BEFF",
      "#DF9C45",
      "#5A2FC6",
      "#0EE4A8",
      "#3C97C2",
      "#5D87FF",
    ],
    series: [],
    xCategories: [],
  },
];

export default casesTrackerData;
