import { Snackbar } from "@mui/material";
import axios from "axios";

class HomePageApi {
  constructor() {
    this.state = {
      open: false,
    };
  }

  GetPatientProfileInfo = (patient_id) => {
    const res = async () => {
      const resp = await axios
        .get(`/user/get/${patient_id}`)
        .catch(function (error) {
          console.log(error);
          if (error.response.request.statusText == "Unauthorized") {
            localStorage.clear();
            window.location.reload();
          }
        });
      return resp;
    };
    return res();
  };

  GetPatientsList = async (
    facilityId,
    startDate,
    endDate,
    medicalStatus,
    annotation_label
  ) => {
    let api = `/portal/patient/get`;
    const queryParams = [];

    if (facilityId != null && facilityId > 0) {
      queryParams.push(`facility=${facilityId}`);
    }

    if (startDate && endDate) {
      queryParams.push(`start_date=${startDate}`, `end_date=${endDate}`);
    }

    if (true) {
      const diseaseParams = medicalStatus
        .map((disease) => `disease=${"Pulmonary TB"}`)
        .join("&");
      queryParams.push(diseaseParams);
    }

    if (annotation_label?.length) {
      const annotationParams = annotation_label
        .map((annotation) => `annotations=${annotation}`)
        .join("&");
      queryParams.push(annotationParams);
    }

    if (queryParams.length) {
      api += `?${queryParams.join("&")}`;
    }

    try {
      const response = await axios.get(api);
      return response;
    } catch (error) {
      console.error(error);
      if (error.response?.request?.statusText === "Unauthorized") {
        localStorage.clear();
        window.location.reload();
      }
      throw error;
    }
  };

  GetAnnotationsChart = async (
    facilityId,
    startDate,
    endDate,
    medicalStatus,
    annotation_label
  ) => {
    let api = `/portal/annotations`;
    const queryParams = [];

    if (facilityId != null && facilityId > 0) {
      queryParams.push(`facility=${facilityId}`);
    }

    if (startDate && endDate) {
      queryParams.push(`start_date=${startDate}`, `end_date=${endDate}`);
    }

    if (medicalStatus?.length) {
      const diseaseParams = medicalStatus
        .map((disease) => `disease=${disease}`)
        .join("&");
      queryParams.push(diseaseParams);
    }

    if (annotation_label?.length) {
      const annotationParams = annotation_label
        .map((annotation) => `annotations=${annotation}`)
        .join("&");
      queryParams.push(annotationParams);
    }

    if (queryParams.length) {
      api += `?${queryParams.join("&")}`;
    }

    try {
      const response = await axios.get(api);
      return response;
    } catch (error) {
      console.error(error);
      if (error.response?.request?.statusText === "Unauthorized") {
        localStorage.clear();
        window.location.reload();
      }
      throw error;
    }
  };
}

export default new HomePageApi();
