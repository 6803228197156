import * as React from "react";
import { useState, useEffect } from "react";
import "./CustomCss/muiInputStyles.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  InputLabel,
  Autocomplete,
  TextField,
  FormControl,
  Snackbar,
  Alert,
  LinearProgress,
  IconButton,
} from "@mui/material";
import DateRangePicker from "./DateRangePicker";
import GetData from "../API/GetData";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { set } from "date-fns";

export default function FiltersCard(props) {
  const {
    audioUrl,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    facilityId,
    setfacilityId,
    patientsList,
    setpatientId,
    fetchImgId,
    selectedOpt,
    setAnnComment,
    AnnComment,
    userRole,
    setselectedOpt,
    modelPredictions,
    setRefresh,
    Refresh,
    setAudioUrl,
    patientId,
    AnnotationSubmitted,
    setAnnotationSubmitted,
    medicalStatus,
    setmedicalStatus,
    setIsloadingSearch,
    IsloadingSearch,
    annotation_label,
    setAnnotation_label,
  } = props;

  const [successMessage, setSuccessMessage] = useState(false);
  const [showmoreAnnotat, setshowmoreAnnotat] = useState(false);

  const handleSearch = (event, newValue) => {
    event.preventDefault();
    if (newValue !== null) {
      setpatientId(newValue.p_id);
    }
  };

  const handleChange = (event) => {
    setfacilityId(event.target.value);
    setIsloadingSearch(true);
  };

  const handleDiseaseChange = (event) => {
    setmedicalStatus(event.target.value == "All" ? null : event.target.value);
    setIsloadingSearch(true);
  };

  const handleLabelChange = (event) => {
    setAnnotation_label(event.target.value);
    setIsloadingSearch(true);
  };

  const modelselectOpt = [
    "Early Stage",
    "Mid Stage",
    "Advanced Stage",
    "Dry",
    "Wet",
    "Normal",
    "Loud",
    "Volunteer",
    "Paroxysmal",
    "Microphone/White Noise",
    "Birds Noise",
    "Fan/Exhaust Noise",
    "Throat Cleaning",
    "Clean Sample",
    "Speech",
    "Vehicle Noise",
    "Unknown Noise",
    "Test",
    "Microphone blasting",
  ];

  const handleValueSelect = (event) => {
    const {
      target: { value },
    } = event;
    setselectedOpt(typeof value === "string" ? value.split(",") : value);
  };

  const facilityOptions = [
    { name: "All", value: "0" },
    { name: userRole == "4" ? "facility 1" : "Independent", value: "1" },
    { name: userRole == "4" ? "facility 2" : "Mayo", value: "2" },
    { name: userRole == "4" ? "facility 3" : "Gulab Devi", value: "3" },
  ];

  const diseaseOptions = [
    { name: "Asthma", value: "Asthma" },
    { name: "Pulmonary TB", value: "Pulmonary TB" },
    { name: "COPD", value: "COPD" },
    { name: "Healthy", value: "Healthy" },
  ];

  const annotationsOptions = [
    { name: "Early Stage", value: "Early Stage" },
    { name: "Mid Stage", value: "Mid Stage" },
    { name: "Advanced Stage", value: "Advanced Stage" },
  ];

  const submitAnnotation = (e) => {
    if (fetchImgId) {
      setAnnotationSubmitted(true);
      const Payload = {
        noise_type: selectedOpt,
        comment: AnnComment ? AnnComment : "",
        rec_id: fetchImgId,
      };
      const res = GetData.PostAnnotationById(Payload);
      res.then((value) => {
        if (value.data.detail === "Added") setAnnotationSubmitted(false);
        setSuccessMessage(true);
      });
      setRefresh(!Refresh);
      e.preventDefault();
    }
  };

  useEffect(() => {
    setAudioUrl(null);
  }, [patientId]);

  return (
    <div className="graph-cards mb-4">
      <div className=" mb-4">
        <div className="d-inline-flex w-100">
          <p className="fs-20 fw-700 text-blue poppins-Med my-auto">
            {/* Search Recording To label ({patientsList?.length}) */}
          </p>
          {localStorage.getItem("role") !== process.env.REACT_APP_DOCTOR && (
            <>
              {/* <FormControl
                sx={{
                  maxHeight: "40px",
                  fontSize: "16px",
                  ml: "auto",
                  mb: 2,
                  width: "200px",
                }}
              >
                <InputLabel id="select-annotation-label">
                  By Annotation
                </InputLabel>
                <Select
                  labelId="select-annotation-label"
                  id="select-annotation"
                  value={annotation_label}
                  multiple
                  onChange={handleLabelChange}
                  input={<OutlinedInput label="Annotation" />}
                  sx={{ fontSize: "16px", maxHeight: "40px" }}
                >
                  {annotationsOptions?.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name.value}
                      sx={{ fontSize: "16px", maxHeight: "40px" }}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {/* <FormControl
                sx={{
                  maxHeight: "40px",
                  fontSize: "16px",
                  ml: "auto",
                  mb: 2,
                  width: "200px",
                }}
              >
                <InputLabel id="select-disease-label">By Disease</InputLabel>
                <Select
                  labelId="select-disease-label"
                  id="select-disease"
                  value={medicalStatus}
                  multiple
                  onChange={handleDiseaseChange}
                  input={<OutlinedInput label="Disease" />}
                  sx={{ fontSize: "16px", maxHeight: "40px" }}
                >
                  {diseaseOptions?.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name.value}
                      sx={{ fontSize: "16px", maxHeight: "40px" }}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </>
          )}
        </div>

        <div className="row mt-3">
          {/* {localStorage.getItem("role") !== process.env.REACT_APP_DOCTOR && (
            <div className="col-2">
              <FormControl
                fullWidth
                sx={{ maxHeight: "45px", fontSize: "16px" }}
              >
                <InputLabel id="select-facility-label">By Facility</InputLabel>
                <Select
                  labelId="select-facility-label"
                  id="select-facility"
                  value={facilityId}
                  onChange={handleChange}
                  input={<OutlinedInput label="Facility" />}
                  // MenuProps={MenuProps}
                >
                  {facilityOptions?.map((name) => (
                    <MenuItem
                      key={name.name}
                      value={name.value}
                      sx={{ fontSize: "16px" }}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )} */}
          <div
            className={`${
              localStorage.getItem("role") !== process.env.REACT_APP_DOCTOR
                ? "col-5"
                : "col-7"
            }`}
          >
            <FormControl fullWidth className="text-field">
              {IsloadingSearch ? (
                <LinearProgress className="mt-5" />
              ) : (
                <Autocomplete
                  id="select-patient"
                  options={patientsList}
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.p_id
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {/* {userRole !== "4" && option.name} $ */}
                      {userRole !== "4" ? option.username : option.p_id}
                    </Box>
                  )}
                  onChange={handleSearch}
                  renderInput={(params) => (
                    <TextField
                      className="inputAutoComplete"
                      {...params}
                      label="Search"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                />
              )}
            </FormControl>
          </div>
          {/* <div className="col-5">
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              lastWeek={false}
              lastMonth={false}
              setIsloadingSearch={setIsloadingSearch}
            />
          </div> */}
        </div>
      </div>
      <Accordion sx={{ position: "initial" }}>
        <AccordionSummary
          className="audio-player-container"
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          disabled={audioUrl ? false : true}
        >
          {audioUrl ? (
            <audio
              src={audioUrl}
              controls
              autoPlay={true}
              controlsList="download"
              className="position-inherit"
            ></audio>
          ) : (
            "Select a recording"
          )}
        </AccordionSummary>
        <AccordionDetails>
          {modelPredictions &&
            modelPredictions?.map((each) => {
              return (
                <div className="col-12 p-3 h-100 poppins-reg bg-primar-light blu-card">
                  <div className="fs-14 fw-700 text-blue mb-1">
                    Model Prediction:
                    <span className="ms-3">
                      {each.model_label == null ? "-------" : each.model_label}
                    </span>
                  </div>
                  <div className="row text-blue text-center py-1">
                    <div className="col-4 fs-14 fw-500">
                      Model 1 :
                      <span className="ms-3">
                        {each.model1_pred == null
                          ? "-------"
                          : each.model1_pred}
                      </span>
                    </div>
                    <div className="col-4 fs-14 fw-500">
                      Model 2 :
                      <span className="ms-3">
                        {each.model2_pred == null
                          ? "-------"
                          : each.model2_pred}
                      </span>
                    </div>
                    <div className="col-4 fs-14 fw-500">
                      Model 3 :
                      <span className="ms-3">
                        {each.model3_pred == null
                          ? "-------"
                          : each.model3_pred}
                      </span>
                    </div>
                  </div>
                  {each.others && (
                    <>
                      <div className="w-100 d-flex text-end">
                        <h4 className="my-auto me-2 text-blue">
                          All annotations
                        </h4>
                        <IconButton
                          onClick={() => setshowmoreAnnotat(!showmoreAnnotat)}
                          className="my-auto"
                        >
                          {showmoreAnnotat ? (
                            <ExpandLess className="fs-2 text-blue" />
                          ) : (
                            <ExpandMore className="fs-2 text-blue" />
                          )}
                        </IconButton>
                      </div>
                      {showmoreAnnotat &&
                        each.others?.map((other) => {
                          return (
                            <div className="d-inline-flex w-100 overflow-scroll">
                              <p className="ms-2 fs-12 p-0">
                                Username: {other.user_name || "-------"}
                              </p>
                              <p className="ms-2 fs-12 p-0">
                                Noise Type:
                                {other.noise_type
                                  ?.map((noises) => noises)
                                  ?.join(", ") || "-------"}
                              </p>
                              <p className="ms-2 fs-12 p-0">
                                Comments: {other.comment || "-------"}
                              </p>
                            </div>
                          );
                        })}
                    </>
                  )}
                </div>
              );
            })}
          {localStorage.getItem("role") ===
          process.env.REACT_APP_VIEWER ? null : (
            <form onSubmit={submitAnnotation}>
              <div className="col-12 my-2">
                <Select
                  fullWidth
                  style={{
                    borderRadius: "6px",
                    boxShadow: "none",
                  }}
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectedOpt}
                  onChange={handleValueSelect}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Chip" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected?.map((value) => (
                        <Chip key={value} label={value} className="fs-4" />
                      ))}
                    </Box>
                  )}
                >
                  {modelselectOpt?.map((opt) => (
                    <MenuItem key={opt} value={opt}>
                      {opt}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="row p-2">
                <div className="col-9 px-0">
                  <textarea
                    value={AnnComment}
                    onChange={(e) => setAnnComment(e.target.value)}
                    className="w-100"
                  />
                </div>
                <button
                  className="btn btn-dark col-2 ms-auto fs-16"
                  type="submit"
                >
                  {AnnotationSubmitted ? <LinearProgress /> : "Annotate"}
                </button>
              </div>
            </form>
          )}
        </AccordionDetails>
      </Accordion>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={successMessage ? true : false}
        onClose={() => setSuccessMessage(false)}
        autoHideDuration={2000}
      >
        <Alert severity="success" sx={{ width: 400, fontSize: "16px" }}>
          Annotated Successfully
        </Alert>
      </Snackbar>
    </div>
  );
}
