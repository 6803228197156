import React, { useState } from "react";
import DateRangePicker from "../../Atoms/DateRangePicker";
import TableInsideComponent from "./TableInsideComponent";

export default function Tables(props) {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    facilityName,
    setpatientId,
  } = props;

  const [isLoadingSearch, setIsloadingSearch] = useState(false);
  const [fileDataCount, setFileDataCount] = useState(0);
  const [testReportDataCount, setTestReportDataCount] = useState(0);
  const [consentFormDataCount, setconsentFormDataCount] = useState(0);
  const [recordingsDataCount, setRecordingsDataCount] = useState(0);
  const [formEnteredDataCount, setFormEnteredDataCount] = useState(0);
  const [commentsDataCount, setCommentsDataCount] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const handleSearchChange = (event) => {
    setSearchWord(event.target.value);
  };
  return (
    <div>
      <div className="row">
        <input
          type="text"
          placeholder="Search by nome, visit Id, phone, etc."
          className="search-input ms-auto col-4"
          value={searchWord}
          onChange={handleSearchChange}
        />
        <div className="ms-auto col-8">
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            lastMonth={false}
            setIsloadingSearch={setIsloadingSearch}
            lastWeek={true}
          />
        </div>
      </div>
      <div className="dash-cards p-4 my-4 bg-aliceblue">
        <div className="d-inline-flex w-100">
          <div className="d-inline-flex w-100">
            <h2 className="my-2 text-primry Lato fs-22 fw-bold position-relative">
              No Form Image Uploads: 3 Days Since Visit creation
            </h2>
            <span className="text-danger ms-auto fs-24 fw-bold Lato">
              {fileDataCount}
            </span>
          </div>
        </div>
        <TableInsideComponent
          column="file"
          startDate={startDate}
          endDate={endDate}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setpatientId={setpatientId}
          facilityName={facilityName}
          setFileDataCount={setFileDataCount}
        />
      </div>
      <div className="dash-cards p-4 my-4 bg-aliceblue-light">
        <div className="d-inline-flex w-100">
          <h2 className="my-2 text-primry Lato fs-22 fw-bold position-relative">
            Form Not Entered for 3 Days Since Visit Creation
          </h2>
          <span className="text-danger ms-auto fs-24 fw-bold Lato">
            {formEnteredDataCount}
          </span>
        </div>
        <TableInsideComponent
          column="form_updated"
          startDate={startDate}
          endDate={endDate}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setpatientId={setpatientId}
          facilityName={facilityName}
          setFormEnteredDataCount={setFormEnteredDataCount}
        />
      </div>
      <div className="dash-cards p-4 my-4 bg-aliceblue">
        <div className="d-inline-flex w-100">
          <h2 className="my-2 text-primry Lato fs-22 fw-bold position-relative">
            Consent Form Not Uploaded for 3 Days Since Visit Creation
          </h2>
          <span className="text-danger ms-auto fs-24 fw-bold Lato">
            {consentFormDataCount}
          </span>
        </div>
        <TableInsideComponent
          column="consent"
          startDate={startDate}
          endDate={endDate}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setpatientId={setpatientId}
          facilityName={facilityName}
          setconsentFormDataCount={setconsentFormDataCount}
        />
      </div>
      <div className="dash-cards p-4 my-4 bg-aliceblue">
        <div className="d-inline-flex w-100">
          <h2 className="my-2 text-primry Lato fs-22 fw-bold position-relative">
            Test Report Photos Not Uploaded for 3 Days Since Visit Creation
          </h2>
          <span className="text-danger ms-auto fs-24 fw-bold Lato">
            {testReportDataCount}
          </span>
        </div>
        <TableInsideComponent
          column="test_report"
          startDate={startDate}
          endDate={endDate}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setpatientId={setpatientId}
          facilityName={facilityName}
          setTestReportDataCount={setTestReportDataCount}
        />
      </div>
      <div className="dash-cards p-4 my-4 bg-aliceblue-light">
        <div className="d-inline-flex w-100">
          <h2 className="my-2 text-primry Lato fs-22 fw-bold position-relative">
            Less Than 3 Recordings Count Within 3 Days Since Visit Creation
          </h2>
          <span className="text-danger ms-auto fs-24 fw-bold Lato">
            {recordingsDataCount}
          </span>
        </div>
        <TableInsideComponent
          startDate={startDate}
          endDate={endDate}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setpatientId={setpatientId}
          facilityName={facilityName}
          column="recording"
          setRecordingsDataCount={setRecordingsDataCount}
        />
      </div>
      <div className="dash-cards p-4 my-4 bg-aliceblue">
        <div className="d-inline-flex w-100">
          <h2 className="my-2 text-primry Lato fs-22 fw-bold position-relative">
            Forms with comments
          </h2>
          <span className="text-danger ms-auto fs-24 fw-bold Lato">
            {commentsDataCount}
          </span>
        </div>
        <TableInsideComponent
          startDate={null}
          endDate={null}
          searchWord={searchWord}
          setSearchWord={setSearchWord}
          setpatientId={setpatientId}
          facilityName={facilityName}
          column="comment"
          setCommentsDataCount={setCommentsDataCount}
        />
      </div>
    </div>
  );
}
